import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/tnp.css";

const Tmultisetup = () => {
  const [numPlayers, setNumPlayers] = useState(4); // 기본 참가 인원 설정
  const [isValid, setIsValid] = useState(true); // 참가 인원 유효성 검사 상태
  const navigate = useNavigate();

  useEffect(() => {
    // 참가 인원이 짝수가 아닌 경우 경고 메시지를 표시
    if (numPlayers % 2 !== 0) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [numPlayers]);

  const handleStartTournament = () => {
    if (!isValid) {
      alert("참가 인원은 짝수여야 합니다.");
      return;
    }
    navigate("/tournament/multi/start", { state: { numPlayers } }); // 참가 인원을 다음 페이지로 전달
  };

  return (
    <div className="tournament-setup-container">
      <h1>멀티전 토너먼트 설정</h1>
      <div className="setup-content">
        <label htmlFor="numPlayers">참가 인원을 선택하세요:</label>
        <select
          id="numPlayers"
          value={numPlayers}
          onChange={(e) => setNumPlayers(parseInt(e.target.value))}
        >
          {[...Array(16).keys()].map((n) => {
            const playerCount = (n + 2) * 2;
            return (
              <option key={playerCount} value={playerCount}>
                {playerCount}명
              </option>
            );
          })}
        </select>

        {!isValid && (
          <p style={{ color: "red" }}>
            참가 인원은 짝수여야 합니다. 다시 선택해주세요.
          </p>
        )}

        <button onClick={handleStartTournament} disabled={!isValid}>
          멀티전 토너먼트 시작
        </button>
      </div>
    </div>
  );
};

export default Tmultisetup;
