import React, { useState } from 'react';
import axios from 'axios';

const Reset = () => {
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isVerified, setIsVerified] = useState(false); // 아이디와 전화번호가 일치하면 true로 변경

  // 아이디와 전화번호 검증 함수
  const handleVerification = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/members/verify-identity', { username, phone });
      if (response.data.verified) {
        setMessage("아이디와 전화번호가 일치합니다. 비밀번호를 재설정할 수 있습니다.");
        setIsVerified(true);
      } else {
        setMessage("아이디와 전화번호가 일치하지 않습니다.");
        setIsVerified(false);
      }
    } catch (error) {
      console.error("검증 실패:", error);
      setMessage("검증에 실패했습니다. 다시 시도해주세요.");
    }
  };

  // 비밀번호 재설정 요청 함수
  const handlePasswordReset = async (e) => {
    e.preventDefault(); // 폼 제출 시 페이지 리로드 방지

    // 비밀번호와 비밀번호 확인이 일치하는지 검증
    if (newPassword !== confirmPassword) {
      setMessage("비밀번호가 일치하지 않습니다.");
      return;
    }

    try {
      const response = await axios.post("/api/members/reset-password", {
        username,
        phone,
        newPassword,
      });
      console.log("비밀번호 재설정 성공:", response.data);
      setMessage("비밀번호가 성공적으로 재설정되었습니다.");
    } catch (error) {
      console.error("비밀번호 재설정 실패:", error);
      setMessage("비밀번호 재설정에 실패했습니다. 다시 시도해주세요.");
    }
  };  

  // 전화번호 입력 시 숫자만 입력되도록 제한
  const handlePhoneInput = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9]/g, ''); // 숫자 외 문자 제거
    setPhone(sanitizedValue);
  };

  return (
    <div>
      {!isVerified ? (
        <form onSubmit={handleVerification}>
          <h2>아이디 및 전화번호 확인</h2>
          {message && <p>{message}</p>}
          <label>
            아이디:
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </label>
          <label>
            전화번호:
            <input
              type="tel"
              value={phone}
              onChange={handlePhoneInput} // 전화번호 입력 시 숫자만 입력되도록 설정
              required
            />
          </label>
          <button type="submit">확인</button>
        </form>
      ) : (
        <form onSubmit={handlePasswordReset}>
          <h2>비밀번호 재설정</h2>
          {message && <p>{message}</p>}
          <label>
            새 비밀번호:
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </label>
          <label>
            비밀번호 확인:
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </label>
          <button type="submit">비밀번호 재설정</button>
        </form>
      )}
    </div>
  );
};

export default Reset;
