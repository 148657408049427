import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../../css/notice.css'; // Importing the correct CSS file
import Pag from '../Pag'; // 페이지네이션 컴포넌트 불러오기

const Noticelist = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' });
  const [sortedAnnouncements, setSortedAnnouncements] = useState([]);

  // 페이지네이션 관련 상태
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // 페이지당 항목 수 설정

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get('/api/announcements');
        setAnnouncements(response.data);
        setSortedAnnouncements(response.data); // 초기 데이터를 sortedAnnouncements에도 저장
      } catch (error) {
        console.error('Error fetching announcements:', error);
      }
    };

    fetchAnnouncements();
  }, []);

  // 내용이 30자를 초과하면 "..."으로 표시하는 함수
  const truncateContent = (content, length) => {
    if (content.length > length) {
      return content.substring(0, length) + '...';
    }
    return content;
  };

  // 정렬 함수
  const sortAnnouncements = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sorted = [...announcements].sort((a, b) => {
      if (key === 'date') {
        return direction === 'asc'
          ? new Date(a[key]) - new Date(b[key])
          : new Date(b[key]) - new Date(a[key]);
      } else if (key === 'title') {
        return direction === 'asc'
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      } else {
        return direction === 'asc' ? a[key] - b[key] : b[key] - a[key];
      }
    });

    setSortedAnnouncements(sorted); // 정렬된 데이터를 UI에만 반영
  };

  const renderArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? '▲' : '▼';
    }
    return '▲'; // 기본적으로 오름차순 화살표를 표시
  };

  // 페이지네이션 관련 계산
  const totalPages = Math.ceil(sortedAnnouncements.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = sortedAnnouncements.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="notice-container">
      <h2 className="notice-title">공지사항 목록</h2>
      <table className="notice-table">
        <thead>
          <tr className="notice-tr">
            <th className="notice-th" onClick={() => sortAnnouncements('id')} style={{ width: '10%' }}>
              번호 {renderArrow('id')}
            </th>
            <th className="notice-th" onClick={() => sortAnnouncements('title')} style={{ width: '30%' }}>
              제목 {renderArrow('title')}
            </th>
            <th className="notice-th" onClick={() => sortAnnouncements('content')} style={{ width: '40%' }}>
              내용 {renderArrow('content')}
            </th>
            <th className="notice-th" onClick={() => sortAnnouncements('date')} style={{ width: '20%' }}>
              날짜 {renderArrow('date')}
            </th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((announcement, index) => (
            <tr key={announcement.id} className="notice-tr">
              <td className="notice-td">{startIndex + index + 1}</td>
              <td className="notice-td">
                <Link to={`/notice/${announcement.id}`} className="notice-link">
                  {announcement.title}
                </Link>
              </td>
              <td className="notice-td">
                <Link to={`/notice/${announcement.id}`} className="notice-link">
                  {truncateContent(announcement.content, 30)}
                </Link>
              </td>
              <td className="notice-td">{new Date(announcement.date).toLocaleDateString()}</td>
            </tr>
          ))}
          {/* 빈 행 추가, 각 셀에 빈 칸을 추가해서 분류선을 유지 */}
          {currentItems.length < itemsPerPage &&
            [...Array(itemsPerPage - currentItems.length)].map((_, i) => (
              <tr key={`empty-${i}`} className="notice-tr">
                <td className="notice-td">&nbsp;</td>
                <td className="notice-td">&nbsp;</td>
                <td className="notice-td">&nbsp;</td>
                <td className="notice-td">&nbsp;</td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* 페이지네이션 컴포넌트 */}
      <Pag
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default Noticelist;
