import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // useParams 추가
import axios from 'axios';
import '../../css/pbp.css'; // rgp.css 파일 연결

const Promotionedit = () => {
  const { id } = useParams(); // URL에서 id 값을 가져옴
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [matchDate, setMatchDate] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // 기존 데이터를 로드하는 함수
    const fetchPromotion = async () => {
      try {
        const response = await axios.get(`/api/promotion/${id}`); // id를 사용하여 데이터를 가져옴
        const { title, content, matchDate } = response.data;
        setTitle(title);
        setContent(content);
        setMatchDate(matchDate);
      } catch (error) {
        console.error('데이터 로드 중 오류:', error);
        setErrorMessage('데이터를 불러오는 중 오류가 발생했습니다.');
      }
    };

    fetchPromotion();
  }, [id]); // id가 변경될 때마다 데이터를 다시 로드

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem('token');
    if (!token) {
      alert('로그인이 필요합니다.');
      navigate('/login');
      return;
    }

    try {
      const response = await axios.put(`/api/promotion/${id}`, { title, matchDate, content }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log('홍보글 수정 성공:', response.data);

      // 게시판 페이지로 이동
      navigate('/tournament-type');
    } catch (error) {
      console.error('홍보글 수정 중 오류:', error);
      setErrorMessage('홍보글 수정 중 문제가 발생했습니다.');
    }
  };

  return (
    <div className="promotion-container">
      <h2 className="post-title">홍보 수정</h2>
      <form className="post-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>제목</label>
          <input 
            type="text" 
            value={title} 
            onChange={(e) => setTitle(e.target.value)} 
            required 
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label>대회 날짜</label>
          <input 
            type="text" 
            value={matchDate} 
            onChange={(e) => setMatchDate(e.target.value)} 
            placeholder="예: 2024-09-28"
            required 
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label>내용</label>
          <textarea 
            value={content} 
            onChange={(e) => setContent(e.target.value)} 
            required 
            className="form-textarea"
          />
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>} {/* 에러 메시지 출력 */}
        <button type="submit" className="submit-button">홍보 수정 완료</button>
      </form>
    </div>
  );
};

export default Promotionedit;
