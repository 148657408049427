// Signp.js
import React from 'react';
import axios from 'axios';

// 유효성 검사 함수들
export const isValidIdOrPassword = (value) => {
  const hasNumber = /\d/.test(value);
  const hasLetter = /[a-zA-Z]/.test(value);
  return value.length >= 4 && hasNumber && hasLetter;
};

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// 아이디 중복 확인 컴포넌트
const Signp = ({ id, setId, setIsIdAvailable, setMessage }) => {
  const checkIdAvailability = async () => {
    console.log('아이디 중복 확인 요청 시작');
    if (!id) {
      setMessage('아이디를 입력하세요.');
      return;
    }

    if (!isValidIdOrPassword(id)) {
      setMessage('아이디는 영문과 숫자를 포함한 4자리 이상이어야 합니다.');
      return;
    }

    try {
      const response = await axios.get(`/api/auth/check-id?id=${id}`, {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        },
      });

      console.log('아이디 중복 확인 응답:', response.data);

      if (response.data.isAvailable) {
        setMessage(response.data.message);
        setIsIdAvailable(true);
      } else {
        setMessage(response.data.message);
        setIsIdAvailable(false);
      }
    } catch (error) {
      console.error('아이디 중복 확인 중 오류:', error);
      setMessage(error.response?.data?.message || '중복 확인 실패');
      setIsIdAvailable(false);
    }
  };

  return (
    <div>
      <label>
        아이디:
        <input
          type="text"
          value={id}
          onChange={(e) => {
            setId(e.target.value);
            setIsIdAvailable(null); // 아이디 변경 시 중복 확인 상태 초기화
          }}
          required
        />
        <button
          type="button"
          onClick={checkIdAvailability}
          disabled={!id}
        >
          아이디 중복 확인
        </button>
      </label>
    </div>
  );
};

export default Signp;
