// App.js

import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import AppRoutes from "./AppRoutes"; // 분리한 라우트 파일

import "../css/base.css";
import "../css/pag.css";
import "../css/table.css";
import '../css/signup.css';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  // 로그아웃 처리
  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('isAdmin');
    setIsLoggedIn(false);
    setIsAdmin(false);
    navigate('/');
  }, [navigate]);

  // 관리자 여부 확인 함수
  const checkAdminStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return;

      const response = await axios.get("/api/check-admin", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 && response.data.isAdmin) {
        setIsAdmin(true);
        localStorage.setItem('isAdmin', 'true');
      } else {
        setIsAdmin(false);
        localStorage.setItem('isAdmin', 'false');
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setIsAdmin(false);
        localStorage.setItem('isAdmin', 'false');
      } else {
        console.error('Error checking admin status:', error.response ? error.response.data : error.message);
      }
    }
  };

  // 로그인 처리
  const handleLogin = async () => {
    try {
      const response = await axios.post('/api/auth/login', { id, password });
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('userId', response.data.userId);
      setIsLoggedIn(true);
      setIsAdmin(response.data.isAdmin);
      localStorage.setItem('isAdmin', response.data.isAdmin ? 'true' : 'false');
      navigate('/');
      window.location.reload();
    } catch (error) {
      console.error('로그인 실패:', error);
      alert('로그인 실패');
    }
  };

  // 로그인 여부 및 관리자 여부 확인
  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedIsAdmin = localStorage.getItem('isAdmin');

    if (token) {
      setIsLoggedIn(true);
      if (storedIsAdmin === 'true') {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
      // 관리자 페이지에 접근 시에만 관리자 확인
      if (window.location.pathname.startsWith("/admin")) {
        checkAdminStatus();
      }
    }
  }, []);

  return (
    <div className="App main-container">
      <header className="App-header">
        <h2 className="h2-control">
          <Link to="/">CHIKKA</Link>
        </h2>

        <nav className="category-nav">
          <Link to="/notice">공지사항</Link>
          <Link to="/tournament-type">당구장대회</Link>
          <Link to="/region">지역별당구장</Link>
          <Link to="/free-board">자유게시판</Link>
        </nav>

        <div className="auth-buttons-container">
          {isLoggedIn ? (
            <>
              <Link to="/attendance">
                <button className="attendance-button">출석체크</button>
              </Link>

              <Link to="/profile">
                <button className="profile-button">회원정보</button>
              </Link>
              {isAdmin && (
                <>
                  <Link to="/admin/notice-manage">
                    <button className="admin-button">공지사항 관리</button>
                  </Link>
                  <Link to="/admin/region-manage">
                    <button className="admin-button">당구장 관리</button>
                  </Link>
                  <Link to="/admin/members">
                    <button className="admin-button">회원목록 관리</button>
                  </Link>
                </>
              )}
              <button className="logout-button" onClick={handleLogout}>로그아웃</button>
            </>
          ) : (
            <div className="auth-buttons">
              <div className="login-container">
                <div className="input-fields-container">
                  <input
                    type="text"
                    placeholder="아이디"
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                  />
                  <input
                    type="password"
                    placeholder="비밀번호"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <button className="login-button" onClick={handleLogin}>로그인</button>
              </div>
              <Link to="/find-username">
                <button>아이디 찾기</button>
              </Link>
              <Link to="/request-reset-password">
                <button>비밀번호 재설정</button>
              </Link>
              <Link to="/signup">
                <button>회원가입</button>
              </Link>
            </div>
          )}
        </div>
      </header>

      <main>
        <AppRoutes isAdmin={isAdmin} />
      </main>
    </div>
  );
};

export default App;
