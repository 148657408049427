import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/free.css'; // free.css 파일 연결

const Freebde = () => {
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`/api/freeboard/${id}`);
        setTitle(response.data.title);
        setContent(response.data.content);
      } catch (err) {
        console.error('Error fetching post:', err);
        setError('게시글을 불러오지 못했습니다.');
      }
    };

    fetchPost();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem('token');
      await axios.put(`/api/freeboard/${id}`, { title, content }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('게시글이 수정되었습니다.');
      navigate(`/free-board/${id}`);
    } catch (error) {
      console.error('Error editing post:', error);
      alert('게시글 수정 중 문제가 발생했습니다.');
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="freeboard-container">
      <h2 className="post-title">게시글 수정</h2>
      <form className="post-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>제목</label>
          <input 
            type="text" 
            value={title} 
            onChange={(e) => setTitle(e.target.value)} 
            required 
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label>내용</label>
          <textarea 
            value={content} 
            onChange={(e) => setContent(e.target.value)} 
            required 
            className="form-textarea"
          />
        </div>
        <button type="submit" className="submit-button">수정 완료</button>
      </form>
    </div>
  );
};

export default Freebde;
