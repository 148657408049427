import React, { useState, useEffect } from "react";
import axios from "axios";
import Calendar from 'react-calendar';
import '../../css/atten.css'; // atten.css 파일 연결

const Atten = ({ markedDates = [] }) => {
  const [user, setUser] = useState({ points: 0 });
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setErrorMessage('로그인이 필요합니다.');
          return;
        }
        
        const response = await axios.get('/api/members/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          setUser(response.data);
        } else {
          setErrorMessage('사용자 정보를 불러오지 못했습니다.');
        }
      } catch (error) {
        setErrorMessage(error.response?.data?.message || '사용자 정보를 가져오는 데 실패했습니다.');
      }
    };

    fetchUserData();
  }, []);

  const handleAttendance = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setErrorMessage('로그인이 필요합니다.');
        return;
      }

      const response = await axios.post('/api/members/attendance', {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setUser((prevUser) => ({
          ...prevUser,
          points: response.data.points,
        }));
        alert('출석 체크 완료! 포인트가 1점 증가했습니다.');
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || '출석 체크 중 문제가 발생했습니다.');
    }
  };

  return (
    <div className="attendance-container">
      <h2 className="attendance-title">출석체크</h2>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <p className="points-display">현재 포인트: {user.points}</p>
      <button className="attendance-button" onClick={handleAttendance}>오늘 출석체크 하기</button>

      <Calendar
        tileContent={({ date }) => {
          if (markedDates.find(d => new Date(d).toDateString() === date.toDateString())) {
            return <p className="tile-check">✔</p>;
          }
          return null;
        }}
      />
    </div>
  );
};

export default Atten;
