import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "../../css/tnp.css";

const Tsinglegame = () => {
  const location = useLocation();
  const { numPlayers } = location.state || { numPlayers: 4 };

  const [players, setPlayers] = useState([]);
  const [billiardHalls, setBilliardHalls] = useState([]);
  const [memberanking, setMemberanking] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isTournamentStarted, setIsTournamentStarted] = useState(false);
  const [finalWinner, setFinalWinner] = useState(null);

  const handleInputChange = (index, field, value) => {
    const updatedPlayers = [...players];
    updatedPlayers[index] = { ...updatedPlayers[index], [field]: value };
    setPlayers(updatedPlayers);
  };

  const handleManualInputComplete = (index) => {
    const updatedPlayers = [...players];
    updatedPlayers[index].inputCompleted = true;
    setPlayers(updatedPlayers);
  };

  const handleEditPlayer = (index) => {
    const updatedPlayers = [...players];
    updatedPlayers[index].inputCompleted = false;
    setPlayers(updatedPlayers);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const memberankingResponse = await axios.get("/api/memberanking");

        if (Array.isArray(memberankingResponse.data)) {
          setMemberanking(memberankingResponse.data);

          const availableHalls = [...new Set(memberankingResponse.data.map((player) => player.billiardHall))];
          setBilliardHalls([...availableHalls, "기타"]);

          const newPlayers = Array.from({ length: numPlayers }, () => ({
            username: "",
            score4: 0,
            score3: 0,
            selectedBilliardHall: "",
            availablePlayers: [],
            inputCompleted: false,
            wins: 0,
            isManualInput: false, // 수기 입력 여부
          }));
          setPlayers(newPlayers);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("데이터를 불러오는 데 문제가 발생했습니다.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [numPlayers]);

  const isReadyToStart = () => players.every((player) => player.inputCompleted);

  const startTournament = () => {
    if (isReadyToStart()) {
      setIsTournamentStarted(true);
      alert("개인전 토너먼트가 시작되었습니다!");
    } else {
      alert("모든 참가자의 정보를 입력해주세요.");
    }
  };

  const handleSelectBilliardHall = (index, billiardHall) => {
    const updatedPlayers = [...players];
    updatedPlayers[index].selectedBilliardHall = billiardHall;
    updatedPlayers[index].isManualInput = billiardHall === "기타";

    if (billiardHall === "기타") {
      updatedPlayers[index].availablePlayers = [];
    } else {
      const availablePlayers = memberanking.filter(
        (player) => player.billiardHall === billiardHall
      );
      updatedPlayers[index].availablePlayers = availablePlayers;
    }
    setPlayers(updatedPlayers);
  };

  const handleSelectPlayer = (index, username) => {
    const selectedPlayer = memberanking.find((player) => player.username === username);

    if (selectedPlayer) {
      const updatedPlayers = [...players];
      updatedPlayers[index] = {
        ...updatedPlayers[index],
        username: username,
        score4: selectedPlayer.score4,
        score3: selectedPlayer.score3,
        inputCompleted: true,
      };
      setPlayers(updatedPlayers);
    }
  };

  const handleWin = (index) => {
    const updatedPlayers = [...players];
    updatedPlayers[index].wins += 1;
    setPlayers(updatedPlayers);
  };

  const handleFinalWin = (index) => {
    setFinalWinner(players[index]);
    alert(`${players[index].username}님이 최종 우승하였습니다!`);
  };

  if (loading) return <p>데이터를 불러오는 중입니다...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="tournament-container">
      <h2>참가 인원 수: {numPlayers}</h2>
      {!isTournamentStarted ? (
        <>
          {players.map((player, index) => (
            <div key={index} className="player-input">
              <select
                onChange={(e) => handleSelectBilliardHall(index, e.target.value)}
                value={player.selectedBilliardHall || ""}
              >
                <option value="">당구장을 선택하세요</option>
                {billiardHalls.map((hall, hallIndex) => (
                  <option key={hallIndex} value={hall}>
                    {hall}
                  </option>
                ))}
              </select>

              {player.isManualInput && !player.inputCompleted ? (
                <>
                  <input
                    type="text"
                    placeholder="별칭:"
                    value={player.username}
                    onChange={(e) => handleInputChange(index, "username", e.target.value)}
                  />
                  <input
                    type="number"
                    placeholder="4구 점수:"
                    value={player.score4 === 0 ? "" : player.score4}
                    onChange={(e) => handleInputChange(index, "score4", e.target.value)}
                  />
                  <input
                    type="number"
                    placeholder="3구 점수:"
                    value={player.score3 === 0 ? "" : player.score3}
                    onChange={(e) => handleInputChange(index, "score3", e.target.value)}
                  />
                  <button onClick={() => handleManualInputComplete(index)}>저장</button>
                </>
              ) : player.selectedBilliardHall !== "기타" && !player.inputCompleted ? (
                <select
                  onChange={(e) => handleSelectPlayer(index, e.target.value)}
                  value={player.username || ""}
                >
                  <option value="">플레이어를 선택하세요</option>
                  {player.availablePlayers.map((option) => (
                    <option key={option.username} value={option.username}>
                      {option.username} (별칭)
                    </option>
                  ))}
                </select>
              ) : null}

              {player.inputCompleted && (
                <div>
                  <p>별칭: {player.username}</p>
                  <p>4구 점수: {player.score4}</p>
                  <p>3구 점수: {player.score3}</p>
                  <button onClick={() => handleEditPlayer(index)}>수정</button>
                </div>
              )}
            </div>
          ))}
          <button onClick={startTournament} disabled={!isReadyToStart()}>
            개인전 토너먼트 시작
          </button>
        </>
      ) : (
        <div>
          <h2>현재 라운드</h2>
          {players.map((player, index) => (
            <div key={index} className="player">
              <p>플레이어: {player.username}</p>
              <p>4구 점수: {player.score4}</p>
              <p>3구 점수: {player.score3}</p>
              <p>승리 횟수: {player.wins}</p>
              <button onClick={() => handleWin(index)}>승리</button>
              <button onClick={() => handleFinalWin(index)}>최종 우승</button>
            </div>
          ))}
          {finalWinner && (
            <div className="final-winner">
              <h2>최종 우승자: {finalWinner.username}</h2>
              <p>4구 점수: {finalWinner.score4}</p>
              <p>3구 점수: {finalWinner.score3}</p>
              <p>승리 횟수: {finalWinner.wins}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Tsinglegame;
