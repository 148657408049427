import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Component, isAdmin }) => {
  // 관리자가 아니라면 리다이렉트
  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }

  // 관리자가 맞다면 해당 컴포넌트를 렌더링
  return <Component />;
};

export default PrivateRoute;
