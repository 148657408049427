import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Pag from '../Pag'; // 페이지네이션 컴포넌트 추가

const Tgametype = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });

  // 페이지네이션 상태 추가
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // 페이지당 항목 수 설정

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);

    const checkAdminStatus = async () => {
      if (token) {
        try {
          const response = await axios.get('/api/check-admin', {
            headers: { Authorization: `Bearer ${token}` }
          });
          setIsAdmin(response.data.isAdmin);
        } catch (error) {
          console.error('Error checking admin status:', error);
        }
      }
    };
    checkAdminStatus();
  }, []);

  // 데이터를 불러오는 useEffect 추가
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/promotion');  // API 호출
        const postsWithNumbers = response.data.map((post, index) => ({
          ...post,
          number: index + 1
        }));
        setPosts(postsWithNumbers);
      } catch (error) {
        console.error('Error fetching promotion posts:', error);
      }
    };
    fetchData();
  }, []); // 빈 배열로 설정하여 컴포넌트 마운트 시 한 번만 실행

  const handlePromotionRequest = () => {
    if (!isLoggedIn) {
      alert('로그인 후 홍보가 가능합니다.');
      navigate('/login');
      return;
    }
    navigate('/promotion-board/new');
  };

  const handleDeletePost = async (id) => {
    const confirmed = window.confirm('정말로 이 게시글을 삭제하시겠습니까?');
    if (!confirmed) return;

    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/api/promotion/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert('게시글이 삭제되었습니다.');
      setPosts((prevPosts) => prevPosts.filter(post => post.id !== id)); // 삭제 후 리스트 업데이트
    } catch (error) {
      console.error('게시글 삭제 중 오류가 발생했습니다.', error);
      alert('게시글 삭제 중 문제가 발생했습니다.');
    }
  };

  const sortData = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sorted = [...posts].sort((a, b) => {
      const aValue = a[key] ? a[key].toString() : '';
      const bValue = b[key] ? b[key].toString() : '';

      if (key === 'number') {
        return direction === 'asc' ? a.number - b.number : b.number - a.number;
      } else if (key === 'title' || key === 'matchDate' || key === 'createdAt') {
        return direction === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      return 0;
    });

    setPosts(sorted);
  };

  const renderArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? '▲' : '▼';
    }
    return '▲';
  };

  // 페이지네이션 계산
  const totalPages = Math.ceil(posts.length / itemsPerPage);
  const currentItems = posts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // 빈 행 추가해서 최소 행 수 유지
  const renderRows = () => {
    let rows = currentItems.map((post, index) => (
      <tr key={post.id}>
        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
        <td>
          <Link to={`/promotion-board/${post.id}`}>{post.title}</Link>
        </td>
        <td>{post.matchDate || '대회 날짜 없음'}</td> {/* matchDate 그대로 사용 */}
        <td>{post.createdAt ? new Date(post.createdAt).toLocaleDateString() : '작성 날짜 없음'}</td>
        {isAdmin && (
          <td>
            <button onClick={() => navigate(`/promotion-board/edit/${post.id}`)}>수정</button>
            <button onClick={() => handleDeletePost(post.id)}>삭제</button>
          </td>
        )}
      </tr>
    ));

    // 빈 행 추가 (최소 10행 유지)
    while (rows.length < itemsPerPage) {
      rows.push(
        <tr key={`empty-${rows.length}`}>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          {isAdmin && <td>&nbsp;</td>}
        </tr>
      );
    }

    return rows;
  };

  return (
    <div className="tournament-type-container">
      <h2>당구장 대회 선택</h2>
      <p>아래에서 원하는 대회 유형을 선택하세요:</p>
      <div className="tournament-options">
        <Link to="/tournament/single">
          <button className="tournament-button">개인전</button>
        </Link>
        <Link to="/tournament/multi">
          <button className="tournament-button">멀티전</button>
        </Link>
        <Link to="/tournament/setup">
          <button className="tournament-button">단체전</button>
        </Link>
      </div>

      {isAdmin && (
        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
          <button className="promotion-button" onClick={handlePromotionRequest}>
            홍보
          </button>
        </div>
      )}

      <div className="promotion-board">
        <h3>대회 홍보 게시판</h3>

        <table>
          <thead>
            <tr>
              <th onClick={() => sortData('number')}>번호 {renderArrow('number')}</th>
              <th onClick={() => sortData('title')}>제목 {renderArrow('title')}</th>
              <th onClick={() => sortData('matchDate')}>대회 날짜 {renderArrow('matchDate')}</th>
              <th onClick={() => sortData('createdAt')}>작성 날짜 {renderArrow('createdAt')}</th>
              {isAdmin && <th>관리</th>}
            </tr>
          </thead>
          <tbody>
            {renderRows()}
          </tbody>
        </table>

        {/* 페이지네이션 컴포넌트 */}
        <Pag
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Tgametype;
