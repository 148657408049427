import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import '../../css/notice.css'; // Import the CSS file

const Noticedetail = () => {
  const { id } = useParams();
  const [announcement, setAnnouncement] = useState(null);
  const [error, setError] = useState('');
  const [currentNumber, setCurrentNumber] = useState(null); // 현재 번호

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get('/api/announcements');

        // 현재 공지사항 가져오기
        const currentAnnouncement = response.data.find(a => a.id === parseInt(id));
        setAnnouncement(currentAnnouncement);

        // 정렬된 목록에서 현재 공지사항의 번호를 찾음
        const sortedAnnouncements = [...response.data].sort((a, b) => b.id - a.id);
        const currentIndex = sortedAnnouncements.findIndex(a => a.id === parseInt(id));
        setCurrentNumber(currentIndex + 1); // 1부터 시작하는 번호 설정
      } catch (error) {
        console.error('Error fetching announcements:', error);
        setError('공지사항을 불러오는 중 문제가 발생했습니다.');
      }
    };

    fetchAnnouncements();
  }, [id]);

  if (error) {
    return <p>{error}</p>;
  }

  if (!announcement) {
    return <p>공지사항을 불러오는 중입니다...</p>;
  }

  return (
    <div className="notice-detail-container">
      <div className="notice-detail-card">
        <table className="notice-table">
          <tbody>
            <tr>
              <td className="notice-label">번호</td>
              <td className="notice-number">{currentNumber}</td> {/* 정렬된 번호 좌측 정렬 */}
            </tr>
            <tr>
              <td className="notice-label">제목</td>
              <td className="notice-title">{announcement.title}</td> {/* 제목 표시 */}
            </tr>
            <tr>
              <td className="notice-label">내용</td>
              <td className="notice-content">{announcement.content}</td> {/* 내용 표시 */}
            </tr>
            <tr>
              <td className="notice-label">작성 날짜</td>
              <td className="notice-date">{new Date(announcement.date).toLocaleDateString()}</td> {/* 작성 날짜 표시 */}
            </tr>
          </tbody>
        </table>
        <div className="back-link-container">
          <Link to="/notice" className="back-link">
            공지사항 목록으로 돌아가기
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Noticedetail;
