import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "../../css/tnp.css";

const Tournamentgame = () => {
  const location = useLocation();
  const { numPlayers } = location.state || { numPlayers: 4 };

  const [players, setPlayers] = useState(
    Array.from({ length: numPlayers }, () => ({
      username: "", // 별칭
      score4: 0,
      score3: 0,
      wins: 0,
      advance: false,
      eliminated: false,
      finalWinner: false,
      winner: false,
      loser: false,
      buttonsHidden: false,
      isManualInput: false,
      inputCompleted: false,
      selectedBilliardHall: "",
      availablePlayers: [],
    }))
  );

  const [memberanking, setMemberanking] = useState([]);
  const [billiardHalls, setBilliardHalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isTournamentStarted, setIsTournamentStarted] = useState(false);
  const [roundTitle, setRoundTitle] = useState("");
  const [advancedPlayers, setAdvancedPlayers] = useState([]);
  const [finalWinner, setFinalWinner] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get("/api/memberanking");

        if (Array.isArray(response.data)) {
          setMemberanking(response.data);
          const uniqueHalls = [...new Set(response.data.map((player) => player.billiardHall))];
          setBilliardHalls([...uniqueHalls, "기타"]);
        } else {
          console.error("Unexpected response format:", response.data);
          setError("데이터 형식이 올바르지 않습니다.");
        }
      } catch (error) {
        console.error("데이터 불러오기 오류:", error);
        setError("데이터를 불러오는 데 문제가 발생했습니다.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    setRoundTitle(getRoundTitle(numPlayers));
  }, [numPlayers]);

  const getRoundTitle = (numPlayersLeft) => {
    if (numPlayersLeft === 2) return "결승전";
    if (numPlayersLeft === 4) return "준결승전";
    if (numPlayersLeft === 8) return "8강전";
    if (numPlayersLeft === 16) return "16강전";
    return `${numPlayersLeft}강전`;
  };

  const isReadyToStart = () => players.every((player) => player.inputCompleted);

  const startTournament = () => {
    if (isReadyToStart()) {
      setIsTournamentStarted(true);
    } else {
      alert("모든 플레이어를 선택해주세요.");
    }
  };

  const handleWin = (playerIndex) => {
    const updatedPlayers = [...players];
    updatedPlayers[playerIndex].wins += 1;
    setPlayers(updatedPlayers);
  };

  const handleAdvance = (playerIndex, opponentIndex) => {
    const updatedPlayers = [...players];
    updatedPlayers[playerIndex].advance = true;
    updatedPlayers[playerIndex].winner = true;
    updatedPlayers[opponentIndex].eliminated = true;
    updatedPlayers[opponentIndex].loser = true;

    updatedPlayers[playerIndex].buttonsHidden = true;
    updatedPlayers[opponentIndex].buttonsHidden = true;

    setPlayers(updatedPlayers);
    setAdvancedPlayers((prev) => [...prev, updatedPlayers[playerIndex].username]);
    alert(`${players[playerIndex].username} 플레이어가 다음 라운드로 진출했습니다!`);
  };

  const handleFinalWin = (playerIndex, opponentIndex) => {
    const updatedPlayers = [...players];
    updatedPlayers[playerIndex].finalWinner = true;
    updatedPlayers[playerIndex].winner = true;
    updatedPlayers[opponentIndex].loser = true;

    updatedPlayers[playerIndex].buttonsHidden = true;
    updatedPlayers[opponentIndex].buttonsHidden = true;

    setPlayers(updatedPlayers);
    setFinalWinner(updatedPlayers[playerIndex]);
    alert(`${players[playerIndex].username} 플레이어가 최종 우승하였습니다!`);
  };

  const handleNextRound = () => {
    setPlayers(
      advancedPlayers.map((username) => ({
        ...players.find((player) => player.username === username),
        wins: 0,
        advance: false,
        buttonsHidden: false,
        winner: false,
        loser: false,
      }))
    );

    setRoundTitle(getRoundTitle(advancedPlayers.length));
    setAdvancedPlayers([]);
  };

  const handleSelectBilliardHall = (index, billiardHall) => {
    const updatedPlayers = [...players];
    updatedPlayers[index].selectedBilliardHall = billiardHall;

    if (billiardHall === "기타") {
      updatedPlayers[index].isManualInput = true;
      updatedPlayers[index].availablePlayers = [];
    } else {
      updatedPlayers[index].isManualInput = false;
      const availablePlayers = memberanking.filter((player) => player.billiardHall === billiardHall);
      updatedPlayers[index].availablePlayers = availablePlayers;
    }

    setPlayers(updatedPlayers);
  };

  const handleSelectPlayer = (index, username) => {
    const selectedPlayer = memberanking.find((player) => player.username === username);

    if (selectedPlayer) {
      const updatedPlayers = [...players];
      updatedPlayers[index] = {
        ...updatedPlayers[index],
        username: selectedPlayer.username,
        score4: selectedPlayer.score4,
        score3: selectedPlayer.score3,
        inputCompleted: true,
      };
      setPlayers(updatedPlayers);
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedPlayers = [...players];
    updatedPlayers[index] = {
      ...updatedPlayers[index],
      [field]: value,
    };
    setPlayers(updatedPlayers);
  };

  const handleManualInputComplete = (index) => {
    const updatedPlayers = [...players];
    updatedPlayers[index].inputCompleted = true;
    setPlayers(updatedPlayers);
  };

  const renderPlayers = () => {
    return players.reduce((acc, player, index) => {
      if (index % 2 === 0 && players[index + 1]) {
        const opponent = players[index + 1];
        const isFinalRound = players.length === 2;
        acc.push(
          <div key={index / 2} className="match">
            <div className="player">
              <p>{player.username}</p>
              <p>4구 점수: {player.score4}</p>
              <p>3구 점수: {player.score3}</p>
              <p>승리 횟수: {player.wins}</p>
              {player.winner && <p>승리</p>}
              {player.loser && <p>패배</p>}
              {!player.eliminated && !player.buttonsHidden && (
                <>
                  <button onClick={() => handleWin(index)}>승리</button>
                  {player.advance ? (
                    <button disabled>다음 라운드 진출</button>
                  ) : (
                    <button
                      onClick={() =>
                        isFinalRound
                          ? handleFinalWin(index, index + 1)
                          : handleAdvance(index, index + 1)
                      }
                    >
                      {isFinalRound ? "최종 우승" : "다음 라운드 진출"}
                    </button>
                  )}
                </>
              )}
            </div>
            <div className="vs">VS</div>
            <div className="player">
              <p>{opponent.username}</p>
              <p>4구 점수: {opponent.score4}</p>
              <p>3구 점수: {opponent.score3}</p>
              <p>승리 횟수: {opponent.wins}</p>
              {opponent.winner && <p>승리</p>}
              {opponent.loser && <p>패배</p>}
              {!opponent.eliminated && !opponent.buttonsHidden && (
                <>
                  <button onClick={() => handleWin(index + 1)}>승리</button>
                  {opponent.advance ? (
                    <button disabled>다음 라운드 진출</button>
                  ) : (
                    <button
                      onClick={() =>
                        isFinalRound
                          ? handleFinalWin(index + 1, index)
                          : handleAdvance(index + 1, index)
                      }
                    >
                      {isFinalRound ? "최종 우승" : "다음 라운드 진출"}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        );
      }
      return acc;
    }, []);
  };

  if (loading) return <p>데이터를 불러오는 중입니다...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="tournament-container">
      <h1>당구장 토너먼트</h1>
      {!isTournamentStarted ? (
        <div className="players-input">
          {players.map((player, index) => (
            <div key={index} className="player-input">
              <select
                onChange={(e) => handleSelectBilliardHall(index, e.target.value)}
                value={player.selectedBilliardHall || ""}
              >
                <option value="">당구장을 선택하세요</option>
                {billiardHalls.map((hall) => (
                  <option key={hall} value={hall}>
                    {hall}
                  </option>
                ))}
              </select>

              {!player.inputCompleted && player.selectedBilliardHall === "기타" ? (
                <>
                  <input
                    type="text"
                    placeholder="별칭:"
                    value={player.username}
                    onChange={(e) => handleInputChange(index, "username", e.target.value)}
                  />
                  <input
                    type="number"
                    placeholder="4구 점수:"
                    value={player.score4 === 0 ? "" : player.score4}
                    onChange={(e) => handleInputChange(index, "score4", e.target.value)}
                  />
                  <input
                    type="number"
                    placeholder="3구 점수:"
                    value={player.score3 === 0 ? "" : player.score3}
                    onChange={(e) => handleInputChange(index, "score3", e.target.value)}
                  />
                  <button onClick={() => handleManualInputComplete(index)}>입력 완료</button>
                </>
              ) : player.selectedBilliardHall !== "기타" ? (
                <select
                  onChange={(e) => handleSelectPlayer(index, e.target.value)}
                  value={player.username || ""}
                  disabled={!player.selectedBilliardHall}
                >
                  <option value="">플레이어를 선택하세요</option>
                  {player.availablePlayers.map((option) => (
                    <option key={option.username} value={option.username}>
                      {option.username} (별칭)
                    </option>
                  ))}
                </select>
              ) : null}

              {player.inputCompleted && (
                <div>
                  <p>별칭: {player.username}</p>
                  <p>4구 점수: {player.score4}</p>
                  <p>3구 점수: {player.score3}</p>
                </div>
              )}
            </div>
          ))}

          <button onClick={startTournament} disabled={!isReadyToStart()}>
            토너먼트 시작
          </button>
        </div>
      ) : (
        <div>
          <div className="round-title">
            <h2>{roundTitle}</h2>
          </div>
          {renderPlayers()}
          {advancedPlayers.length > 0 && (
            <div className="next-round-players">
              <h3>다음 라운드 진출자:</h3>
              <ul>
                {advancedPlayers.map((username, idx) => (
                  <li key={idx}>{username}</li>
                ))}
              </ul>
            </div>
          )}
          {advancedPlayers.length > 0 && (
            <button onClick={handleNextRound}>다음 라운드 시작</button>
          )}
          {finalWinner && (
            <div className="final-winner">
              <h2>최종 우승자:</h2>
              <p>별칭: {finalWinner.username}</p>
              <p>4구 점수: {finalWinner.score4}</p>
              <p>3구 점수: {finalWinner.score3}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Tournamentgame;
