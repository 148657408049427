import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pag from '../Pag'; // 페이지네이션 컴포넌트 불러오기

const Aregion = () => {
  const [billiardHalls, setBilliardHalls] = useState([]);
  const [newBilliardHall, setNewBilliardHall] = useState({
    region: '',
    name: '',
    address: '',
    website: '',
    youtube: '' // 유튜브 주소 추가
  });
  const [editHallId, setEditHallId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(1); // 페이지 상태 추가
  const itemsPerPage = 10; // 페이지당 항목 수 설정

  useEffect(() => {
    const fetchBilliardHalls = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token available. Please log in.');
          return;
        }

        const response = await axios.get('/api/billiard-halls', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (Array.isArray(response.data)) {
          setBilliardHalls(response.data);
        } else {
          console.error('Unexpected data format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching billiard halls:', error);
      }
    };

    fetchBilliardHalls();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewBilliardHall({ ...newBilliardHall, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem('token');

    if (!token) {
      console.error('No token found');
      setLoading(false);
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const formData = {
        region: newBilliardHall.region,
        name: newBilliardHall.name,
        address: newBilliardHall.address,
        website: newBilliardHall.website || '',
        youtube: newBilliardHall.youtube || ''
      };

      let response;
      if (editHallId) {
        response = await axios.put(`/api/billiard-halls/${editHallId}`, formData, config);
        setBilliardHalls(prevHalls =>
          prevHalls.map(hall => hall.id === editHallId ? response.data : hall)
        );
      } else {
        response = await axios.post('/api/billiard-halls', formData, config);
        setBilliardHalls([...billiardHalls, response.data]);
      }

      setNewBilliardHall({
        region: '',
        name: '',
        address: '',
        website: '',
        youtube: ''
      });
      setEditHallId(null);
    } catch (error) {
      console.error('Error saving billiard hall:', error);
      alert(`Error saving billiard hall: ${error.response ? error.response.data.message : error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (hall) => {
    setEditHallId(hall.id);
    setNewBilliardHall({
      region: hall.region || '',
      name: hall.name || '',
      address: hall.address || '',
      website: hall.website || '',
      youtube: hall.youtube || ''
    });
  };

  const handleDelete = async (id) => {
    if (window.confirm('삭제하시겠습니까?')) {
      setLoading(true);
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        await axios.delete(`/api/billiard-halls/${id}`, { headers: { Authorization: `Bearer ${token}` } });
        setBilliardHalls(billiardHalls.filter(hall => hall.id !== id));
        alert('당구장이 삭제되었습니다.');
      } catch (error) {
        console.error('Error deleting billiard hall:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const sortData = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });

    const sorted = [...billiardHalls].sort((a, b) => {
      if (key === 'id') return direction === 'asc' ? a.id - b.id : b.id - a.id;
      return direction === 'asc' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
    });
    setBilliardHalls(sorted);
  };

  const renderArrow = (key) => sortConfig.key === key ? (sortConfig.direction === 'asc' ? '▲' : '▼') : '▲';

  // 페이지네이션 관련 계산
  const totalPages = Math.ceil(billiardHalls.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = billiardHalls.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderRows = () => {
    let rows = currentItems.map((hall, index) => (
      <tr key={hall.id}>
        <td>{startIndex + index + 1}</td>
        <td>{hall.region || '지역 없음'}</td>
        <td>{hall.name || '이름 없음'}</td>
        <td>{hall.address || '주소 없음'}</td>
        <td>
          {hall.website ? (
            <a href={hall.website} target="_blank" rel="noopener noreferrer">
              {hall.website}
            </a>
          ) : '사이트 없음'}
        </td>
        <td>
          {hall.youtube ? (
            <a href={hall.youtube} target="_blank" rel="noopener noreferrer">
              {hall.youtube}
            </a>
          ) : '유튜브 없음'}
        </td>
        <td>
          <button onClick={() => handleEdit(hall)}>수정</button>
          <button onClick={() => handleDelete(hall.id)}>삭제</button>
        </td>
      </tr>
    ));

    // 빈 행을 추가해서 최소 10행 유지
    while (rows.length < itemsPerPage) {
      rows.push(
        <tr key={`empty-${rows.length}`}>
          <td style={{ border: '1px solid #ccc' }}>&nbsp;</td>
          <td style={{ border: '1px solid #ccc' }}>&nbsp;</td>
          <td style={{ border: '1px solid #ccc' }}>&nbsp;</td>
          <td style={{ border: '1px solid #ccc' }}>&nbsp;</td>
          <td style={{ border: '1px solid #ccc' }}>&nbsp;</td>
          <td style={{ border: '1px solid #ccc' }}>&nbsp;</td>
          <td style={{ border: '1px solid #ccc' }}>&nbsp;</td>
        </tr>
      );
    }

    return rows;
  };

  return (
    <div>
      <h2>{editHallId ? '당구장 수정' : '당구장 정보 추가'}</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>지역</label>
          <input type="text" name="region" value={newBilliardHall.region} onChange={handleChange} required />
        </div>
        <div>
          <label>당구장 이름</label>
          <input type="text" name="name" value={newBilliardHall.name} onChange={handleChange} required />
        </div>
        <div>
          <label>주소</label>
          <input type="text" name="address" value={newBilliardHall.address} onChange={handleChange} required />
        </div>
        <div>
          <label>사이트 주소</label>
          <input type="url" name="website" value={newBilliardHall.website} onChange={handleChange} />
        </div>
        <div>
          <label>유튜브 주소</label>
          <input type="url" name="youtube" value={newBilliardHall.youtube} onChange={handleChange} />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? '처리 중...' : editHallId ? '수정 완료' : '추가'}
        </button>
      </form>

      {loading ? (
        <p>로딩 중...</p>
      ) : (
        <>
          <h2>현재 등록된 당구장</h2>
          <table>
            <thead>
              <tr>
                <th onClick={() => sortData('id')}>번호 {renderArrow('id')}</th>
                <th onClick={() => sortData('region')}>지역 {renderArrow('region')}</th>
                <th onClick={() => sortData('name')}>당구장 이름 {renderArrow('name')}</th>
                <th onClick={() => sortData('address')}>주소 {renderArrow('address')}</th>
                <th>사이트 주소</th>
                <th>유튜브 주소</th>
                <th>관리</th>
              </tr>
            </thead>
            <tbody>{renderRows()}</tbody>
          </table>

          {/* 페이지 네비게이션 */}
          <Pag
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
};

export default Aregion;

