import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/notice.css'; // Import the CSS file
import Pag from '../Pag'; // 페이지네이션 컴포넌트 불러오기

const Anoticem = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' });
  const [sortedAnnouncements, setSortedAnnouncements] = useState([]);
  const navigate = useNavigate();

  // 페이지네이션 상태 추가
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // 페이지당 항목 수 설정

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get('/api/announcements');
        setAnnouncements(response.data);
        setSortedAnnouncements(response.data); // 초기 데이터를 sortedAnnouncements에도 저장
      } catch (error) {
        console.error('Error fetching announcements:', error);
        alert('공지사항을 불러오는 중 문제가 발생했습니다.');
      }
    };

    fetchAnnouncements();
  }, []);

  const handleDelete = async (announcementId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('로그인이 필요합니다.');
      return;
    }
  
    try {
      // DELETE 요청을 보내면서 Authorization 헤더에 토큰을 포함
      await axios.delete(`/api/announcements/${announcementId}`, {
        headers: {
          Authorization: `Bearer ${token}`, // 토큰을 추가
        },
      });
      alert('공지사항이 삭제되었습니다.');
      // 삭제 후 UI 갱신 로직 추가
    } catch (error) {
      console.error('공지사항 삭제 중 오류:', error);
      alert('공지사항 삭제 중 문제가 발생했습니다.');
    }
  };
  

  // 내용 자르기 (30자 제한)
  const truncateContent = (content, limit) => {
    return content.length > limit ? content.slice(0, limit) + '...' : content;
  };

  // 정렬 함수 (UI에서만 정렬 처리)
  const sortAnnouncements = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sorted = [...announcements].sort((a, b) => {
      if (key === 'date') {
        return direction === 'asc'
          ? new Date(a[key]) - new Date(b[key])
          : new Date(b[key]) - new Date(a[key]);
      } else if (key === 'title') {
        return direction === 'asc'
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      } else {
        return direction === 'asc' ? a[key] - b[key] : b[key] - a[key];
      }
    });

    setSortedAnnouncements(sorted); // 정렬된 데이터를 UI에만 반영
  };

  // 페이지네이션 처리
  const totalPages = Math.ceil(sortedAnnouncements.length / itemsPerPage);
  const currentItems = sortedAnnouncements.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderRows = () => {
    let rows = currentItems.map((announcement, index) => (
      <tr key={announcement.id} className="notice-tr">
        <td className="notice-td">{sortConfig.direction === 'asc' ? (currentPage - 1) * itemsPerPage + index + 1 : sortedAnnouncements.length - ((currentPage - 1) * itemsPerPage + index)}</td>
        <td className="notice-td">{announcement.title}</td>
        <td className="notice-td">{truncateContent(announcement.content, 30)}</td>
        <td className="notice-td">{new Date(announcement.date).toLocaleDateString()}</td>
        <td className="notice-td">
          <button className="back-link" onClick={() => navigate(`/admin/notice/edit/${announcement.id}`)}>수정</button>
          <button className="back-link" onClick={() => handleDelete(announcement.id)}>삭제</button>
        </td>
      </tr>
    ));

    // 빈 행을 추가해서 최소 10개 행을 유지
    while (rows.length < itemsPerPage) {
      rows.push(
        <tr key={`empty-${rows.length}`} className="notice-tr">
          <td className="notice-td">&nbsp;</td>
          <td className="notice-td">&nbsp;</td>
          <td className="notice-td">&nbsp;</td>
          <td className="notice-td">&nbsp;</td>
          <td className="notice-td">&nbsp;</td>
        </tr>
      );
    }

    return rows;
  };

  return (
    <div className="notice-container">
      <h2 className="notice-title">공지사항 관리</h2>
      <button className="back-link" onClick={() => navigate('/admin/notice/new')}>공지 추가</button>
      <table className="notice-table">
        <thead>
          <tr className="notice-tr">
            <th className="notice-th" onClick={() => sortAnnouncements('id')}>
              번호 {sortConfig.key === 'id' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : '▲'}
            </th>
            <th className="notice-th" onClick={() => sortAnnouncements('title')}>
              제목 {sortConfig.key === 'title' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : '▲'}
            </th>
            <th className="notice-th">내용</th>
            <th className="notice-th" onClick={() => sortAnnouncements('date')}>
              날짜 {sortConfig.key === 'date' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : '▲'}
            </th>
            <th className="notice-th">관리</th>
          </tr>
        </thead>
        <tbody>
          {renderRows()}
        </tbody>
      </table>

      {/* 페이지네이션 컴포넌트 */}
      <Pag
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default Anoticem;
