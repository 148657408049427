import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/signup.css'; // CSS 파일 임포트
import Signp, { isValidEmail } from './Signp'; // Signp 및 유효성 검사 함수 임포트

const Sign = () => {
  const [id, setId] = useState(''); // 아이디 필수 입력
  const [username, setUsername] = useState(''); // 별칭 필수 입력
  const [phonePart1, setPhonePart1] = useState('');
  const [phonePart2, setPhonePart2] = useState('');
  const [phonePart3, setPhonePart3] = useState('');
  const [emailPart1, setEmailPart1] = useState('');
  const [emailPart2, setEmailPart2] = useState('');
  const [customDomain, setCustomDomain] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [score3, setScore3] = useState('');
  const [highrun4, setHighrun4] = useState('');
  const [highrun3, setHighrun3] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [score4Options] = useState([30, 50, 80, 100, 120, 150, 200, 250, 300, 400, 500, 700, 1000, 1500, 2000]);
  const [selectedScore4, setSelectedScore4] = useState('');
  const [selectedBilliardHall, setSelectedBilliardHall] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [message, setMessage] = useState('');
  const [isIdAvailable, setIsIdAvailable] = useState(null); // 아이디 중복 확인 상태 추가
  const [billiardHalls, setBilliardHalls] = useState([]); // 당구장 목록 상태 추가
  const [regions, setRegions] = useState([]); // 지역 목록 상태 추가
  const navigate = useNavigate(); // navigate 사용

  const domainOptions = ['naver.com', 'gmail.com', 'hotmail.com', 'hanmail.net'];

  const isValidIdOrPassword = (value) => {
    const hasNumber = /\d/.test(value);
    const hasLetter = /[a-zA-Z]/.test(value);
    return value.length >= 4 && hasNumber && hasLetter;
  };

  // 당구장 목록을 API로부터 가져오는 함수
  useEffect(() => {
    const fetchBilliardHalls = async () => {
      try {
        const response = await axios.get('/api/billiard-halls'); // 백엔드 API 호출
        setBilliardHalls(response.data); // 응답 데이터를 상태에 저장

        // 지역 목록 추출 (중복 제거)
        const uniqueRegions = [...new Set(response.data.map(hall => hall.region))];
        setRegions(uniqueRegions); // 지역 목록 상태에 저장
      } catch (error) {
        console.error('당구장 목록을 불러오는 데 실패했습니다.', error);
        setMessage('당구장 목록을 불러오는 데 실패했습니다.');
      }
    };

    fetchBilliardHalls(); // 컴포넌트 마운트 시 API 호출
  }, []);

  const fetchMembers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/memberanking', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("회원 목록:", response.data);
    } catch (error) {
      console.error("회원 목록을 불러오는 데 실패했습니다.", error);
    }
  };

  const handleSignup = async (signupData) => {
    try {
      const response = await axios.post("/api/auth/signup", signupData);
      console.log("회원가입 성공:", response.data);
      setMessage("회원가입이 완료되었습니다.");

      // 회원 목록 새로 고침
      await fetchMembers();
      navigate('/'); // 회원가입 후 메인 페이지로 리다이렉트
    } catch (error) {
      console.error("회원가입 중 오류:", error);
      setMessage("회원가입에 실패했습니다.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isValidEmail(`${emailPart1}@${emailPart2 || customDomain}`)) {
      setMessage('올바른 이메일 형식을 입력하세요.');
      return;
    }

    if (password !== confirmPassword) {
      setMessage('비밀번호가 일치하지 않습니다.');
      return;
    }

    if (!isIdAvailable) {
      setMessage('아이디 중복 확인을 먼저 해주세요.');
      return;
    }

    const phone = `${phonePart1}-${phonePart2}-${phonePart3}`;
    const email = `${emailPart1}@${emailPart2 || customDomain}`;

    const signupData = {
      id,
      username,
      phone: phone.replace(/-/g, ''),
      email,
      password,
      score4: selectedScore4,
      score3,
      highrun4,
      highrun3,
      birthYear,
      billiardHall: selectedBilliardHall,
      region: selectedRegion,
    };

    handleSignup(signupData); // 회원가입 요청
  };

  return (
    <form onSubmit={handleSubmit} className="signup-form">
      <h2>회원가입</h2>
      {message && <p className="message">{message}</p>}

      {/* 아이디 중복 확인 및 공통 유효성 로직 */}
      <Signp
        id={id} 
        setId={setId} 
        setIsIdAvailable={setIsIdAvailable} 
        isValidIdOrPassword={isValidIdOrPassword} 
        setMessage={setMessage} 
      />

      {/* 나머지 입력 필드 (별칭, 전화번호, 이메일 등) */}
      <label>
        별칭:
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
      </label>

      {/* 전화번호 입력 */}
      <label>
        전화번호:
        <div style={{ display: 'flex', gap: '5px' }}>
          <input
            type="text"
            value={phonePart1}
            onChange={(e) => setPhonePart1(e.target.value.slice(0, 4))}
            maxLength={4}
            placeholder="0000"
            required
          />
          <input
            type="text"
            value={phonePart2}
            onChange={(e) => setPhonePart2(e.target.value.slice(0, 4))}
            maxLength={4}
            placeholder="0000"
            required
          />
          <input
            type="text"
            value={phonePart3}
            onChange={(e) => setPhonePart3(e.target.value.slice(0, 4))}
            maxLength={4}
            placeholder="0000"
            required
          />
        </div>
      </label>

      {/* 이메일 입력 */}
      <label>
        이메일:
        <div style={{ display: 'flex', gap: '5px' }}>
          <input
            type="text"
            value={emailPart1}
            onChange={(e) => setEmailPart1(e.target.value)}
            placeholder="example"
            required
          />
          <span>@</span>
          <input
            type="text"
            value={emailPart2 || customDomain}
            onChange={(e) => setCustomDomain(e.target.value)}
            placeholder="domain.com"
            required
          />
          <select onChange={(e) => setEmailPart2(e.target.value)} value={emailPart2}>
            <option value="">도메인 선택</option>
            {domainOptions.map((domain) => (
              <option key={domain} value={domain}>
                {domain}
              </option>
            ))}
            <option value="custom">직접 입력</option>
          </select>
        </div>
      </label>

      {/* 4구 점수 선택 드롭다운 */}
      <label>
        4구 점수:
        <select onChange={(e) => setSelectedScore4(e.target.value)} value={selectedScore4}>
          <option value="">점수를 선택하세요</option>
          {score4Options.map((score, index) => (
            <option key={index} value={score}>
              {score}
            </option>
          ))}
        </select>
      </label>

      {/* 지역 선택 */}
      <label>
        지역:
        <select onChange={(e) => setSelectedRegion(e.target.value)} value={selectedRegion}>
          <option value="">지역을 선택하세요</option>
          {regions.length > 0 ? (
            regions.map((region, index) => (
              <option key={index} value={region}>
                {region}
              </option>
            ))
          ) : (
            <option value="">지역이 없습니다</option>
          )}
        </select>
      </label>

      {/* 당구장 이름 선택 */}
      <label>
        당구장 이름:
        <select onChange={(e) => setSelectedBilliardHall(e.target.value)} value={selectedBilliardHall}>
          <option value="">당구장을 선택하세요</option>
          {billiardHalls.length > 0 ? (
            billiardHalls.map((hall) => (
              <option key={hall.id} value={hall.name}>
                {hall.name}
              </option>
            ))
          ) : (
            <option value="">당구장이 없습니다</option>
          )}
        </select>
      </label>

      {/* 3구 점수 입력 */}
      <label>
        3구 점수:
        <input
          type="text"
          value={score3}
          onChange={(e) => setScore3(e.target.value)}
          maxLength={4}
        />
      </label>

      {/* 4구 하이런 입력 */}
      <label>
        4구 하이런:
        <input
          type="text"
          value={highrun4}
          onChange={(e) => setHighrun4(e.target.value)}
          maxLength={4}
        />
      </label>

      {/* 3구 하이런 입력 */}
      <label>
        3구 하이런:
        <input
          type="text"
          value={highrun3}
          onChange={(e) => setHighrun3(e.target.value)}
          maxLength={4}
        />
      </label>

      {/* 생년 입력 */}
      <label>
        생년:
        <input
          type="text"
          value={birthYear}
          onChange={(e) => setBirthYear(e.target.value)}
          maxLength={4}
        />
      </label>

      {/* 비밀번호 입력 */}
      <label>
        비밀번호:
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </label>

      {/* 비밀번호 확인 입력 */}
      <label>
        비밀번호 확인:
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
      </label>

      <button type="submit" disabled={!isIdAvailable}>
        가입
      </button>
    </form>
  );
};

export default Sign;
