// AppRoutes.js

import React from "react";
import { Routes, Route } from "react-router-dom";

// 컴포넌트 임포트
import PrivateRoute from './Admin/PrivateRoute';
import Anoticem from "./Admin/Anoticem";
import Anoticeedit from './Admin/Anoticeedit';
import Aregion from './Admin/Aregion';
import Amemberl from './Admin/Amemberl';
import Sign from "./Admin/Sign";
import Reset from "./Admin/Reset";
import Finduser from "./Admin/Finduser";
import Uprof from "./Admin/Uprof";
import Signud from './Admin/Signud';
import Main from "./Main";
import Noticelist from './Category/Noticelist';
import Noticedetail from './Category/Noticedetail';
import Freebdp from "./Category/Freebdp";
import Freebd from './Category/Freebd';
import Freetail from './Category/Freetail';
import Freebde from './Category/Freebde';
import Regionpage from "./Category/Regionpage";
import Promotiondetail from './Tournament/Promotiondetail';
import Promotionrequest from './Tournament/Promotionrequest';
import Promotionedit from './Tournament/Promotionedit';
import Atten from './Category/Atten';

//Tournament
import Tmultisetup from "./Tournament/Tmultisetup";
import Tmultigame from "./Tournament/Tmultigame";
import Tgametype from "./Tournament/Tgametype";
import Tournamentsetup from "./Tournament/Tournamentsetup";
import Tournamentgame from "./Tournament/Tournamentgame";
import Tsinglesetup from './Tournament/Tsinglesetup';
import Tsinglegame from "./Tournament/Tsinglegame";  // Tsinglegame1 임포트
import Tsinglerequest from "./Tournament/Tsinglerequest";
import Tsingledetail from "./Tournament/Tsingledetail";

// 광고
import Ad1 from "./Ad/Ad1";
import Ad2 from "./Ad/Ad2";
import Ad3 from "./Ad/Ad3";
import GyPage from './Pages/gy';

// NotFound 컴포넌트
const NotFound = () => <h2>404 - 페이지를 찾을 수 없습니다.</h2>;

const AppRoutes = ({ isAdmin }) => {
  return (
    <Routes>
      {/* 일반 사용자 페이지 */}
      <Route path="/" element={<Main />} />
      <Route path="/notice" element={<Noticelist />} />
      <Route path="/notice/:id" element={<Noticedetail />} />
      <Route path="/promotion-board/:id" element={<Promotiondetail />} />
      <Route path="/promotion-board/new" element={<Promotionrequest />} />
      <Route path="/promotion-board/edit/:id" element={<Promotionedit />} />
      <Route path="/region" element={<Regionpage />} />
      <Route path="/free-board" element={<Freebdp />} />
      <Route path="/free-board/:id" element={<Freetail />} />
      <Route path="/free-board/edit/:id" element={<Freebde />} />
      <Route path="/free-board/new" element={<Freebd />} />

      {/*토너먼트*/}
      <Route path="/tournament" element={<Tournamentgame />} />
      <Route path="/tournament/single" element={<Tsinglesetup />} />
      <Route path="/tournament/single/start" element={<Tsinglegame />} /> {/* Tsinglegame1 사용 */}
      <Route path="/tournament/single/:id" element={<Tsingledetail />} />
      <Route path="/tournament/single/new" element={<Tsinglerequest />} />
      <Route path="/tournament/single/edit/:id" element={<Tsinglegame />} /> {/* Tsinglegame2 사용 */}
      <Route path="/tournament-type" element={<Tgametype />} />
      <Route path="/tournament/setup" element={<Tournamentsetup />} />
      <Route path="/tournament/multi" element={<Tmultisetup />} />
      <Route path="/tournament/multi/start" element={<Tmultigame />} />

      {/* 사용자 프로필 및 인증 페이지 */}
      <Route path="/profile" element={<Uprof />} />
      <Route path="/profile/:userId" element={<Uprof />} /> {/* userId로 Uprof 라우트 설정 */}
      <Route path="/update-info" element={<Signud />} />
      <Route path="/signup" element={<Sign />} />
      <Route path="/request-reset-password" element={<Reset />} />
      <Route path="/find-username" element={<Finduser />} />

      <Route path="/attendance" element={<Atten />} />

      {/* 광고 페이지 */}
      <Route path="/ad1" element={<Ad1 adName="ad1" />} />
      <Route path="/ad2" element={<Ad2 adName="ad2" />} />
      <Route path="/ad3" element={<Ad3 adName="ad3" />} />

      {/* 관리자 페이지 */}
      <Route path="/admin/notice-manage" element={<PrivateRoute element={Anoticem} isAdmin={isAdmin} />} />
      <Route path="/admin/notice/edit/:id" element={<PrivateRoute element={Anoticeedit} isAdmin={isAdmin} />} />
      <Route path="/admin/notice/new" element={<PrivateRoute element={Anoticeedit} isAdmin={isAdmin} />} />
      <Route path="/admin/region-manage" element={<PrivateRoute element={Aregion} isAdmin={isAdmin} />} />
      <Route path="/admin/members" element={<PrivateRoute element={Amemberl} isAdmin={isAdmin} />} />

      {/* GY 페이지 */}
      <Route path="/gy" element={<GyPage />} />

      {/* NotFound Route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
