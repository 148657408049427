import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import RankingsTable from "./Rkt";
import Pagination from "./Pag";
import "../css/table.css";
import "../css/search.css";

const MainContent = () => {
  const [memberanking, setMemberanking] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchField, setSearchField] = useState("id");
  const [sortConfig, setSortConfig] = useState({ key: "id", direction: "ascending" });
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredMemberanking, setFilteredMemberanking] = useState([]);
  const [selectedTab, setSelectedTab] = useState("detailed"); // 탭 선택 상태 추가
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/memberanking', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        console.log('받아온 회원 데이터:', response.data); // 데이터를 로깅
        const data = response.data;
        // memberanking이 배열이 아닐 경우 빈 배열로 초기화
        setMemberanking(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Error fetching memberanking:', error);
        setMemberanking([]); // 에러가 발생했을 경우 빈 배열로 설정
      }
    };
  
    fetchData();
  }, []);
  
  // 검색어와 선택된 필드에 따른 필터링 로직
  useEffect(() => {
    const filteredData = memberanking.filter((rank) => {
      const fieldValue = rank[searchField];
      return fieldValue
        ? fieldValue.toString().toLowerCase().includes(searchTerm.toLowerCase())
        : true;
    });
    setFilteredMemberanking(filteredData);
  }, [searchTerm, searchField, memberanking]); // 검색어, 필드, 데이터 변경 시 필터링

  const handleSearchClick = () => {
    setCurrentPage(1);
    const filteredData = memberanking.filter((rank) => {
      const fieldValue = rank[searchField];
      return fieldValue
        ? fieldValue.toString().toLowerCase().includes(searchTerm.toLowerCase())
        : false;
    });
    setFilteredMemberanking(filteredData);
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setCurrentPage(1); // 탭이 변경될 때 페이지를 첫 페이지로 설정
  };

  // 상세랭킹 탭에 맞는 컬럼 설정
  const detailedRankingHeaders = [
    { key: "username", label: "별칭" },
    { key: "score4", label: "4구 점수" },
    { key: "score3", label: "3구 점수" },
    { key: "highrun4", label: "4구 하이런" },
    { key: "highrun3", label: "3구 하이런" },
    { key: "birthYear", label: "생년" },
    { key: "region", label: "지역" },
    { key: "billiardHall", label: "당구장이름" } // 필드명을 billiardHall로 변경
  ];

  // 정렬 로직
  const sortedMemberanking = useMemo(() => {
    const sorted = [...filteredMemberanking].sort((a, b) => {
      const aValue = a[sortConfig.key] || ""; // null 또는 undefined 값 처리
      const bValue = b[sortConfig.key] || ""; // null 또는 undefined 값 처리

      if (typeof aValue === "number" && typeof bValue === "number") {
        // 숫자 데이터에 대한 정렬
        return sortConfig.direction === "ascending" ? aValue - bValue : bValue - aValue;
      } else if (typeof aValue === "string" && typeof bValue === "string") {
        // 문자열 데이터에 대한 정렬
        return sortConfig.direction === "ascending"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else {
        return 0;
      }
    });
    return sorted;
  }, [filteredMemberanking, sortConfig]);

  const currentItems = useMemo(() => {
    return sortedMemberanking.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  }, [sortedMemberanking, currentPage, itemsPerPage]);

  const totalPages = useMemo(() => Math.ceil(sortedMemberanking.length / itemsPerPage), [
    sortedMemberanking.length,
    itemsPerPage,
  ]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // 여기에 addRowNumber 함수를 사용하여 번호를 추가합니다.
  const addRowNumber = (items, currentPage, itemsPerPage, sortConfig, totalItems) => {
    return items.map((item, index) => {
      const baseIndex = (currentPage - 1) * itemsPerPage + index + 1;
      const rowNumber = sortConfig.direction === "ascending"
        ? baseIndex
        : totalItems - baseIndex + 1; // 내림차순일 때 번호 부여
      
      return { ...item, number: rowNumber };
    });
  };

  // 여기서 currentItems에 번호를 추가한 itemsWithRowNumber를 생성합니다.
  const itemsWithRowNumber = addRowNumber(
    currentItems,
    currentPage,
    itemsPerPage,
    sortConfig,
    sortedMemberanking.length
  );

  return (
    <div className="main-content">
      {/* 검색창 */}
      <div className="search-bar-container">
        <div className="search-bar">
          <select
            className="search-bar__select"
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
          >
            <option value="username">별칭</option>
            <option value="score4">4구 점수</option>
            <option value="score3">3구 점수</option>
            <option value="highrun4">4구 하이런</option>
            <option value="highrun3">3구 하이런</option>
            <option value="birthYear">생년</option>
            <option value="region">지역</option>
            <option value="billiardHall">당구장이름</option> {/* 필드명을 billiardHall로 변경 */}
          </select>
          <input
            className="search-bar__input"
            type="text"
            placeholder="검색어를 입력하세요"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button onClick={handleSearchClick}>검색</button>
        </div>
      </div>

      {/* 버튼식 탭 */}
      <div className="ranking-tabs">
        <button onClick={() => handleTabClick("weekly")} className={selectedTab === "weekly" ? "active" : ""}>
          주간랭킹
        </button>
        <button onClick={() => handleTabClick("monthly")} className={selectedTab === "monthly" ? "active" : ""}>
          월간랭킹
        </button>
        <button onClick={() => handleTabClick("yearly")} className={selectedTab === "yearly" ? "active" : ""}>
          연간랭킹
        </button>
        <button onClick={() => handleTabClick("detailed")} className={selectedTab === "detailed" ? "active" : ""}>
          상세랭킹
        </button>
      </div>

      {/* 랭킹 테이블 */}
      <RankingsTable
        currentItems={itemsWithRowNumber} // 번호가 추가된 아이템 전달
        sortConfig={sortConfig}
        requestSort={requestSort}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        selectedTab={selectedTab} // 선택된 탭 전달
        totalItems={sortedMemberanking.length} // 총 아이템 수 전달
        columnHeaders={selectedTab === "detailed" ? detailedRankingHeaders : null} // 상세랭킹에만 맞는 컬럼 전달
      />

      {/* 페이지 네비게이션 */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={setCurrentPage}
      />
    </div>
  );
};

export default MainContent;
