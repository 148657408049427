import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/pbp.css'; // CSS 파일 연결

const Promotiondetail = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [applicants, setApplicants] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAuthor, setIsAuthor] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentUser, setCurrentUser] = useState(''); // 현재 사용자 정보 저장
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  // 신청자 목록 불러오기
  const fetchApplicants = useCallback(async (postId) => {
    try {
      const response = await axios.get(`/api/promotion/${postId}/applicants`);
      setApplicants(response.data);
    } catch (error) {
      console.error('신청자 목록을 불러오는 중 오류가 발생했습니다.', error);
      setError('신청자 목록을 불러오는 중 문제가 발생했습니다.');
    }
  }, []);

  // 사용자 권한 확인
  const checkUserPermissions = useCallback((authorId) => {
    const token = localStorage.getItem("token");
    const currentUserId = localStorage.getItem("userId"); // 사용자 ID를 localStorage에서 가져옴
  
    if (token && currentUserId) {
      axios
        .get(`/api/memberanking/${currentUserId}`, { // 사용자 정보를 가져오는 API 경로 수정
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const user = response.data;
          console.log("Current user:", user); // 사용자 정보 로그로 확인
          setCurrentUser(user.id); // 사용자 아이디 저장
          setIsAuthor(user.id === authorId); // 작성자 확인
          setIsAdmin(user.role === 'admin'); // 관리자 확인
        })
        .catch((error) => {
          console.error("사용자 확인 중 오류가 발생했습니다.", error);
        });
    }
  }, []);

  // 게시글 정보 불러오기
  const fetchPost = useCallback(async (postId) => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/promotion/${postId}`);
      setPost(response.data);
      checkUserPermissions(response.data.authorId); // 게시글 작성자 ID 확인
    } catch (error) {
      console.error('게시글을 불러오는 중 오류가 발생했습니다.', error);
      setError('게시글을 불러오는 중 문제가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  }, [checkUserPermissions]);

  // 로그인 상태 확인
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchPost(id);
      fetchApplicants(id);
    }
  }, [id, fetchPost, fetchApplicants]);

  // 이미 신청했는지 확인
  const isAlreadyApplied = applicants.some(applicant => applicant.id === currentUser);

  // 게시글 삭제
  const handleDeletePost = async () => {
    const confirmed = window.confirm('정말로 이 게시글을 삭제하시겠습니까?');
    if (!confirmed) return;

    if (!isAuthor && !isAdmin) {
      alert('게시글을 삭제할 권한이 없습니다.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/api/promotion/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('게시글이 삭제되었습니다.');
      navigate('/tournament-type');
    } catch (error) {
      console.error('게시글 삭제 중 오류가 발생했습니다.', error);
      alert('게시글 삭제 중 문제가 발생했습니다.');
    }
  };

  // 게시글 수정
  const handleEditPost = () => {
    if (!isAuthor && !isAdmin) {
      alert('게시글을 수정할 권한이 없습니다.');
      return;
    }
    navigate(`/promotion-board/edit/${id}`);
  };

  // 신청 기능
  const handleApply = async () => {
    if (!isLoggedIn) {
      alert('로그인 후 신청할 수 있습니다.');
      return;
    }
  
    const token = localStorage.getItem('token');
    try {
      await axios.post(
        `/api/promotion/${id}/apply`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert('신청이 완료되었습니다.');
      fetchApplicants(id);
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data.message === "이미 신청하셨습니다.") {
        alert("이미 신청하셨습니다.");
      } else {
        console.error('신청 중 오류가 발생했습니다.', error);
        setError('신청 중 문제가 발생했습니다.');
      }
    }
  };

  if (loading) {
    return <div>로딩 중...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!post) {
    return <div>게시글을 불러오는 중...</div>;
  }

  return (
    <div className="promotion-container">
    <h2>{post.title}</h2>
    <p>{post.content}</p>
    <p>작성자 ID: {post.authorId || '작성자 없음'}</p> {/* 작성자 ID 표시 */}
    <p>작성일: {new Date(post.createdAt).toLocaleString()}</p>
    <p>조회수: {post.viewCount || 0}</p>

      <div className="promotion-actions">
        {(isAuthor || isAdmin) ? (
          <>
            <button onClick={handleEditPost}>수정</button>
            <button className="delete" onClick={handleDeletePost}>삭제</button>
          </>
        ) : (
          <p>수정 및 삭제 권한이 없습니다.</p>
        )}
      </div>

      <div>
        <button onClick={handleApply} disabled={isAlreadyApplied}>
          {isAlreadyApplied ? "이미 신청하셨습니다." : "신청하기"}
        </button>
      </div>

      <div className="applicant-list">
        <h3>신청자 목록</h3>
        {applicants.length > 0 ? (
          <ul>
            {applicants.map((applicant, index) => (
              <li key={index}>
                <p>아이디: {applicant.id || '아이디 없음'}</p>
                <p>별칭: {applicant.username || '별칭 없음'}</p>
                <p>당구장 이름: {applicant.billiardHall || '당구장 이름 없음'}</p>
              </li>  
            ))}
          </ul>
        ) : (
          <p>신청자가 없습니다.</p>
        )}
      </div>
    </div>
  );
};

export default Promotiondetail;
