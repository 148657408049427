import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/tnp.css'; // CSS 파일 연결

const Tsinglerequest = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [matchDate, setMatchDate] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem('token');
    if (!token) {
      alert('로그인이 필요합니다.');
      navigate('/login');
      return;
    }

    try {
      // 매치 생성 후 서버로부터 생성된 매치의 ID를 받아옴
      const response = await axios.post('/api/singlematch', { title, matchDate, content }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log('매치 신청 성공:', response.data);
      // 매치가 성공적으로 생성된 후 매치 게시판으로 이동
      navigate(`/tournament/single`);
    } catch (error) {
      console.error('매치 신청 중 오류:', error);
      alert('매치 신청 중 문제가 발생했습니다.');
    }
  };

  return (
    <div className="promotion-container">
      <h2 className="post-title">개인전 매치 신청</h2>
      <form className="post-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>제목</label>
          <input 
            type="text" 
            value={title} 
            onChange={(e) => setTitle(e.target.value)} 
            required 
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label>매치 날짜</label>
          <input 
            type="text" 
            value={matchDate} 
            onChange={(e) => setMatchDate(e.target.value)} 
            placeholder="예: 2024-09-28"
            required 
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label>내용</label>
          <textarea 
            value={content} 
            onChange={(e) => setContent(e.target.value)} 
            required 
            className="form-textarea"
          />
        </div>
        <button type="submit" className="submit-button">매치 신청 완료</button>
      </form>
    </div>
  );
};

export default Tsinglerequest;
