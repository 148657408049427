import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Signud = () => {
  const [user, setUser] = useState(null);
  const [updatedId, setUpdatedId] = useState(''); // id로 변경
  const [updatedScore4, setUpdatedScore4] = useState('');
  const [updatedScore3, setUpdatedScore3] = useState('');
  const [updatedHighrun4, setUpdatedHighrun4] = useState('');
  const [updatedHighrun3, setUpdatedHighrun3] = useState('');
  const [updatedBirthYear, setUpdatedBirthYear] = useState('');
  const [updatedRegion, setUpdatedRegion] = useState('');
  const [updatedBilliards, setUpdatedBilliards] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // 드롭다운 옵션
  const [score4Options, setScore4Options] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [billiardsOptions, setBilliardsOptions] = useState([]);

  const navigate = useNavigate();

  // 사용자 정보 및 드롭다운 옵션 불러오기
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setErrorMessage('로그인이 필요합니다.');
          return;
        }

        const [userResponse, optionsResponse] = await Promise.all([
          axios.get('/api/memberanking/user', { headers: { Authorization: `Bearer ${token}` } }), // 특정 회원 조회 API
          axios.get('/api/memberanking/additional-info-options', { headers: { Authorization: `Bearer ${token}` } }) // 드롭다운 옵션 불러오기
        ]);

        if (userResponse.data) {
          setUser(userResponse.data);
          setUpdatedId(userResponse.data.id); // 사용자 ID 설정
          setUpdatedScore4(userResponse.data.score4 || '');
          setUpdatedScore3(userResponse.data.score3 || '');
          setUpdatedHighrun4(userResponse.data.highrun4 || '');
          setUpdatedHighrun3(userResponse.data.highrun3 || '');
          setUpdatedBirthYear(userResponse.data.birthYear || '');
          setUpdatedRegion(userResponse.data.region || '');
          setUpdatedBilliards(userResponse.data.billiards || '');
        }

        if (optionsResponse.data) {
          setScore4Options(Array.isArray(optionsResponse.data.score4Options) ? optionsResponse.data.score4Options : []);
          setRegionOptions(Array.isArray(optionsResponse.data.regionOptions) ? optionsResponse.data.regionOptions : []);
          setBilliardsOptions(Array.isArray(optionsResponse.data.billiardsOptions) ? optionsResponse.data.billiardsOptions : []);
        }
      } catch (error) {
        setErrorMessage(error.response?.data?.message || '데이터를 가져오는 데 실패했습니다.');
      }
    };

    fetchData();
  }, []);

  const updateUserInfo = async () => {
    try {
      const confirmUpdate = window.confirm('100포인트가 차감됩니다. 수정하시겠습니까?');
      if (!confirmUpdate) return;

      const token = localStorage.getItem('token');
      if (!token) {
        setErrorMessage('로그인이 필요합니다.');
        return;
      }

      if (user.points < 100) {
        alert('포인트가 부족합니다.');
        return;
      }

      const response = await axios.put(
        `/api/memberanking/${user.id}/points`, // 사용자 포인트 수정 API
        {
          id: updatedId, // 사용자 ID
          score4: updatedScore4,
          score3: updatedScore3,
          highrun4: updatedHighrun4,
          highrun3: updatedHighrun3,
          birthYear: updatedBirthYear,
          region: updatedRegion,
          billiards: updatedBilliards,
          points: user.points - 100, // 포인트 차감
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUser(response.data);
      alert('정보가 수정되었습니다. 포인트가 100점 차감되었습니다.');
      navigate('/profile');
    } catch (error) {
      setErrorMessage(error.response?.data?.message || '정보 수정에 실패했습니다.');
    }
  };

  const handleCancel = () => {
    navigate('/profile'); // 취소 버튼 클릭 시 프로필 페이지로 이동
  };

  return (
    <div>
      <h2>추가 정보 수정</h2>
      {errorMessage && <p>{errorMessage}</p>}
      {user ? (
        <div>
          <label>
            아이디 (id):
            <input
              type="text"
              value={updatedId} // 사용자 ID 수정
              onChange={(e) => setUpdatedId(e.target.value)}
            />
          </label>
          <label>
            4구 점수:
            <select
              value={updatedScore4}
              onChange={(e) => setUpdatedScore4(e.target.value)}
            >
              <option value="">4구 점수를 선택하세요</option>
              {score4Options.map((score) => (
                <option key={score} value={score}>
                  {score}
                </option>
              ))}
            </select>
          </label>
          <label>
            3구 점수:
            <input
              type="number"
              value={updatedScore3}
              onChange={(e) => setUpdatedScore3(e.target.value)}
            />
          </label>
          <label>
            4구 하이런:
            <input
              type="number"
              value={updatedHighrun4}
              onChange={(e) => setUpdatedHighrun4(e.target.value)}
            />
          </label>
          <label>
            3구 하이런:
            <input
              type="number"
              value={updatedHighrun3}
              onChange={(e) => setUpdatedHighrun3(e.target.value)}
            />
          </label>
          <label>
            생년:
            <input
              type="number"
              value={updatedBirthYear}
              onChange={(e) => setUpdatedBirthYear(e.target.value)}
            />
          </label>
          <label>
            지역:
            <select
              value={updatedRegion}
              onChange={(e) => setUpdatedRegion(e.target.value)}
            >
              <option value="">지역을 선택하세요</option>
              {regionOptions.map((region) => (
                <option key={region} value={region}>
                  {region}
                </option>
              ))}
            </select>
          </label>
          <label>
            당구장 이름:
            <select
              value={updatedBilliards}
              onChange={(e) => setUpdatedBilliards(e.target.value)}
            >
              <option value="">당구장 이름을 선택하세요</option>
              {billiardsOptions.map((billiards) => (
                <option key={billiards} value={billiards}>
                  {billiards}
                </option>
              ))}
            </select>
          </label>
          <p>포인트: {user.points}</p>
          <button onClick={updateUserInfo}>저장</button>
          <button onClick={handleCancel}>취소</button>
        </div>
      ) : (
        <p>사용자 정보를 불러오는 중입니다...</p>
      )}
    </div>
  );
};

export default Signud;
