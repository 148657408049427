import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // useNavigate 임포트 추가
import axios from 'axios';
import '../../css/free.css'; // free.css 파일 연결
import Pag from '../Pag'; // 페이지네이션 컴포넌트 불러오기

const Freebdp = () => {
  const [posts, setPosts] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // 페이지당 항목 수 설정
  const navigate = useNavigate(); // useNavigate 사용

  // 자유게시판 게시글 불러오기
  const fetchPosts = async () => {
    try {
      const response = await axios.get('/api/freeboard');
      setPosts(response.data);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  // 사용자 로그인 상태 및 관리자 여부 확인
  const checkLoginStatus = () => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
      axios
        .get('/api/check-admin', {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setIsAdmin(response.data.isAdmin);
        })
        .catch((error) => {
          console.error('Error checking admin status:', error);
        });
    }
  };

  useEffect(() => {
    fetchPosts();
    checkLoginStatus();
  }, []);

  // 게시글 삭제 핸들러
  const handleDeletePost = async (postId) => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`/api/freeboard/${postId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
      alert('게시글이 삭제되었습니다.');
    } catch (error) {
      console.error('Error deleting post:', error);
      alert('게시글 삭제 중 문제가 발생했습니다.');
    }
  };

  // 글쓰기 페이지로 이동
  const handleWritePost = () => {
    navigate('/free-board/new');
  };

  // 게시글 정렬 함수
  const sortData = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedPosts = [...posts].sort((a, b) => {
      if (key === 'id') {
        return direction === 'asc' ? a.id - b.id : b.id - a.id;
      } else if (key === 'author' || key === 'title') {
        return direction === 'asc'
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      } else if (key === 'date') {
        return direction === 'asc'
          ? new Date(a[key]) - new Date(b[key])
          : new Date(b[key]) - new Date(a[key]);
      }
      return direction === 'asc' ? a[key] - b[key] : b[key] - a[key];
    });

    setPosts(sortedPosts);
  };

  // 정렬 화살표 표시
  const renderArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? '▲' : '▼';
    }
    return '▲';
  };

  // 페이지네이션 관련 계산
  const totalPages = Math.ceil(posts.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = posts.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // 빈 셀 추가
  const renderRows = () => {
    let rows = currentItems.map((post, index) => (
      <tr key={post.id}>
        <td>{startIndex + index + 1}</td>
        <td>
          <Link to={`/free-board/${post.id}`}>{post.title}</Link>
        </td>
        <td>{post.author}</td>
        <td>{new Date(post.date).toLocaleDateString()}</td>
        <td>{post.viewCount || 0}</td>
        <td>{post.commentCount || 0}</td>
        {isAdmin && (
          <td>
            <button onClick={() => handleDeletePost(post.id)}>삭제</button>
          </td>
        )}
      </tr>
    ));

    // 빈 행을 추가해서 최소 10행 유지
    while (rows.length < itemsPerPage) {
      rows.push(
        <tr key={`empty-${rows.length}`}>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          {isAdmin && <td>&nbsp;</td>}
        </tr>
      );
    }

    return rows;
  };

  return (
    <div className="freeboard-container">
      <h1 className="post-title">자유게시판</h1>

      {/* 로그인된 사용자에게만 글쓰기 버튼 표시 */}
      {isLoggedIn && (
        <div className="write-post-button">
          <button onClick={handleWritePost}>글쓰기</button>
        </div>
      )}

      <table className="freeboard-table">
        <thead>
          <tr>
            <th onClick={() => sortData('id')}>번호 {renderArrow('id')}</th>
            <th onClick={() => sortData('title')}>제목 {renderArrow('title')}</th>
            <th onClick={() => sortData('author')}>글쓴이 {renderArrow('author')}</th>
            <th onClick={() => sortData('date')}>작성날짜 {renderArrow('date')}</th>
            <th onClick={() => sortData('views')}>조회수 {renderArrow('views')}</th>
            <th onClick={() => sortData('commentCount')}>댓글 개수 {renderArrow('commentCount')}</th>
            {isAdmin && <th>관리</th>}
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>

      {/* 페이지네이션 컴포넌트 */}
      <Pag
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default Freebdp;
