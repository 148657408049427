import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "../../css/tnp.css";

const Tsinglesetup = () => {
  const [numPlayers, setNumPlayers] = useState(2); // 기본 참가 인원 설정
  const navigate = useNavigate();

  const [posts, setPosts] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token); // 로그인 여부 확인

    const checkAdminStatus = async () => {
      if (token) {
        try {
          const response = await axios.get("/api/check-admin", {
            headers: { Authorization: `Bearer ${token}` },
          });
          setIsAdmin(response.data.isAdmin); // 관리자 여부 설정
        } catch (error) {
          console.error("Error checking admin status:", error);
        }
      }
    };
    checkAdminStatus();
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await axios.get(`/api/singlematch`);
      const postsWithNumbers = response.data.map((post, index) => ({
        ...post,
        number: index + 1,
      }));
      setPosts(postsWithNumbers);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const handleStartTournament = () => {
    console.log("참가 인원:", numPlayers); // 디버그 로그 추가
    navigate("/tournament/single/start", { state: { numPlayers } }); // numPlayers 상태값을 state로 전달
  };

  const handlePromotionRequest = () => {
    if (!isLoggedIn) {
      alert("로그인 후 매치를 생성할 수 있습니다.");
      navigate("/login");
      return;
    }
    navigate("/tournament/single/new");
  };

  const handleDeletePost = async (id) => {
    const confirmed = window.confirm("정말로 이 게시글을 삭제하시겠습니까?");
    if (!confirmed) return;

    try {
      const token = localStorage.getItem("token");
      await axios.delete(`/api/singlematch/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert("게시글이 삭제되었습니다.");
      setPosts((prevPosts) => prevPosts.filter((post) => post.id !== id)); // 삭제 후 리스트 업데이트
    } catch (error) {
      console.error("게시글 삭제 중 오류가 발생했습니다.", error);
      alert("게시글 삭제 중 문제가 발생했습니다.");
    }
  };

  const sortData = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sorted = [...posts].sort((a, b) => {
      const aValue = a[key] ? a[key].toString() : "";
      const bValue = b[key] ? b[key].toString() : "";

      if (key === "number") {
        return direction === "asc" ? a.number - b.number : b.number - a.number;
      } else if (key === "title" || key === "date" || key === "createdAt") {
        return direction === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      return 0;
    });

    setPosts(sorted);
  };

  const renderArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? "▲" : "▼";
    }
    return "▲";
  };

  // 행 렌더링 함수
  const renderRows = () => {
    return posts.length > 0 ? (
      posts.map((post, index) => (
        <tr key={post.id}>
          <td>{post.number}</td>
          <td>
            <Link to={`/tournament/single/${post.id}`}>{post.title}</Link>
          </td>
          <td>{post.matchDate || "대회 날짜 없음"}</td>
          <td>
            {post.createdAt
              ? new Date(post.createdAt).toLocaleDateString()
              : "작성 날짜 없음"}
          </td>
          {(isAdmin || isLoggedIn) && (
            // 관리자 또는 로그인한 사용자에게만 삭제/수정 버튼 표시
            <td>
              <button
                onClick={() => navigate(`/tournament/single/edit/${post.id}`)}
              >
                수정
              </button>
              <button onClick={() => handleDeletePost(post.id)}>삭제</button>
            </td>
          )}
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={isAdmin || isLoggedIn ? 5 : 4}>게시글이 없습니다.</td>
      </tr>
    );
  };

  return (
    <div className="tournament-setup-container">
      <h1>개인전 토너먼트 설정</h1>
      <div className="setup-content">
        <label>참가 인원을 선택하세요:</label>
        <select
          value={numPlayers}
          onChange={(e) => setNumPlayers(parseInt(e.target.value))}
        >
          {[...Array(15).keys()].map((n) => (
            <option key={n + 2} value={n + 2}>
              {n + 2}명
            </option>
          ))}
        </select>
        <button onClick={handleStartTournament}>개인전 토너먼트 시작</button>
      </div>

      {/* 개인전 매치 게시판 추가 */}
      <div className="promotion-board">
        <h3>개인전 매치 게시판</h3>

        {isLoggedIn && (
          // 로그인한 사용자에게 매치 생성 버튼 표시
          <div style={{ textAlign: "right", marginBottom: "10px" }}>
            <button
              className="promotion-button"
              onClick={handlePromotionRequest}
            >
              매치 만들기
            </button>
          </div>
        )}

        <table>
          <thead>
            <tr>
              <th onClick={() => sortData("number")}>
                번호 {renderArrow("number")}
              </th>
              <th onClick={() => sortData("title")}>
                제목 {renderArrow("title")}
              </th>
              <th onClick={() => sortData("matchDate")}>
                대회 날짜 {renderArrow("matchDate")}
              </th>
              <th onClick={() => sortData("createdAt")}>
                작성 날짜 {renderArrow("createdAt")}
              </th>
              {(isAdmin || isLoggedIn) && <th>관리</th>}
            </tr>
          </thead>
          <tbody>{renderRows()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default Tsinglesetup;
