import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../../css/upf.css'; // 스타일 파일
import Uprofp from './Uprofp'; // 추가 정보 수정 컴포넌트 임포트

const Uprof = () => {
  const { userId: paramUserId } = useParams();
  const [user, setUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedUsername, setUpdatedUsername] = useState('');
  const [updatedPhone, setUpdatedPhone] = useState('');
  const [updatedPassword, setUpdatedPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // 비밀번호 확인 추가
  const [updatedEmail, setUpdatedEmail] = useState('');
  const [updatedBirthYear, setUpdatedBirthYear] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // fetchUser 함수에 useCallback 적용
  const fetchUser = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const userId = paramUserId || localStorage.getItem('userId'); // URL에 userId가 없을 경우 localStorage에서 가져옴

      if (!token || !userId) {
        setErrorMessage('로그인이 필요합니다.');
        return;
      }

      const response = await axios.get(`/api/memberanking/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data) {
        setUser(response.data);
        setUpdatedUsername(response.data.username);
        setUpdatedPhone(response.data.phone);
        setUpdatedEmail(response.data.email);
        setUpdatedBirthYear(response.data.birthYear);
      } else {
        setErrorMessage('사용자 정보를 가져오지 못했습니다.');
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || '사용자 정보를 가져오는 데 실패했습니다.');
    }
  }, [paramUserId]);

  useEffect(() => {
    fetchUser(); // fetchUser 호출
  }, [fetchUser]); // fetchUser를 의존성 배열에 추가

  // 회원 정보 수정 함수
  const updateUserInfo = async () => {
    if (updatedPassword && updatedPassword !== confirmPassword) {
      setErrorMessage('비밀번호가 일치하지 않습니다.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const userId = paramUserId || localStorage.getItem('userId');

      if (!token || !userId) {
        setErrorMessage('로그인이 필요합니다.');
        return;
      }

      // 서버에 기본 정보 업데이트 요청
      const response = await axios.put(
        `/api/memberanking/${userId}/update`,
        {
          username: updatedUsername,
          phone: updatedPhone,
          password: updatedPassword, // 비밀번호 변경 가능 (선택 사항)
          email: updatedEmail,
          birthYear: updatedBirthYear,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // 기존의 추가 정보는 유지하고, 새로운 기본 정보만 업데이트
      setUser((prevUser) => ({
        ...prevUser,
        username: response.data.username,
        phone: response.data.phone,
        email: response.data.email,
        birthYear: response.data.birthYear,
      }));

      setIsEditing(false);
      alert('회원 정보가 수정되었습니다.');
    } catch (error) {
      setErrorMessage(error.response?.data?.message || '회원 정보 수정에 실패했습니다.');
    }
  };

  // 회원 삭제 함수 정의
  const deleteUser = async (userId) => {
    const confirmed = window.confirm('정말로 회원 탈퇴하시겠습니까?');
    if (!confirmed) return;

    try {
      const token = localStorage.getItem('token');
      if (!token || !userId) {
        setErrorMessage('로그인이 필요합니다.');
        return;
      }

      const response = await axios.delete(`/api/memberanking/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        alert('회원 탈퇴가 완료되었습니다.');
        localStorage.removeItem('token');
        window.location.href = '/'; // 탈퇴 후 페이지 이동
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || '회원 탈퇴에 실패했습니다.');
    }
  };

  return (
    <div className="user-profile-container">
      <h2>회원 정보</h2>
      {errorMessage && <p>{errorMessage}</p>}
      {user ? (
        <div>
          <div className="basic-info">
            <h3>기본 정보</h3>
            {isEditing ? (
              <div>
                <label>
                  별칭:
                  <input
                    type="text"
                    value={updatedUsername}
                    onChange={(e) => setUpdatedUsername(e.target.value)}
                  />
                </label>
                <label>
                  전화번호:
                  <input
                    type="text"
                    value={updatedPhone}
                    onChange={(e) => setUpdatedPhone(e.target.value)}
                  />
                </label>
                <label>
                  비밀번호:
                  <input
                    type="password"
                    value={updatedPassword}
                    onChange={(e) => setUpdatedPassword(e.target.value)}
                    placeholder="비밀번호를 변경하려면 입력하세요"
                  />
                </label>
                <label>
                  비밀번호 확인:
                  <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="비밀번호를 확인하세요"
                  />
                </label>
                <label>
                  이메일:
                  <input
                    type="text"
                    value={updatedEmail}
                    onChange={(e) => setUpdatedEmail(e.target.value)}
                  />
                </label>
                <label>
                  생년:
                  <input
                    type="text"
                    value={updatedBirthYear}
                    onChange={(e) => setUpdatedBirthYear(e.target.value)}
                  />
                </label>
                <button onClick={updateUserInfo}>저장</button>
                <button className="cancel-btn" onClick={() => setIsEditing(false)}>취소</button>
              </div>
            ) : (
              <div>
                <p>아이디: {user.id}</p>
                <p>별칭: {user.username}</p>
                <p>전화번호: {user.phone}</p>
                <p>이메일: {user.email}</p>
                <p>생년: {user.birthYear}</p>
                <button onClick={() => setIsEditing(true)}>기본 정보 수정</button>
              </div>
            )}
          </div>

          {/* 추가 정보 수정 컴포넌트 */}
          <Uprofp user={user} setUser={setUser} />

          <div className="delete-account">
            <button className="cancel-btn" onClick={() => deleteUser(user.id)}>회원 탈퇴</button>
          </div>
        </div>
      ) : (
        <p className="loading-message">회원 정보를 불러오는 중입니다...</p>
      )}
    </div>
  );
};

export default Uprof;
