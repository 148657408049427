import React from "react";

const Pag = ({ currentPage, totalPages, handlePageChange }) => {
  const renderPageNumbers = () => {
    let pageNumbers = [];

    let startPage = Math.max(currentPage - 4, 1);
    let endPage = Math.min(currentPage + 5, totalPages);

    if (startPage > 1) pageNumbers.push(1, "...");
    for (let i = startPage; i <= endPage; i++) pageNumbers.push(i);
    if (endPage < totalPages) pageNumbers.push("...", totalPages);

    return pageNumbers;
  };

  return (
    <div className="pagination">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        &laquo;
      </button>
      {renderPageNumbers().map((page, index) =>
        page === "..." ? (
          <span key={index}>...</span>
        ) : (
          <button
            key={index}
            onClick={() => handlePageChange(page)}
            className={currentPage === page ? "active" : ""}
          >
            {page}
          </button>
        )
      )}
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        &raquo;
      </button>
    </div>
  );
};

export default Pag;
