import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../../css/aml.css'; // CSS 파일을 임포트

const Amemberl = () => {
  const [members, setMembers] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [editingMemberId, setEditingMemberId] = useState(null);
  const [editPoints, setEditPoints] = useState(0); 
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' });

  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setErrorMessage('로그인이 필요합니다.');
        return;
      }

      const response = await axios.get('/api/memberanking', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && Array.isArray(response.data)) {
        setMembers(response.data);
      } else {
        setErrorMessage('회원 정보를 불러오는 데 실패했습니다.');
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      setErrorMessage('회원 정보를 불러오는 데 실패했습니다.');
      console.error('Error fetching members:', error);
    }
  };

  const deleteMember = async (id) => {
    const confirmDelete = window.confirm('정말로 삭제하시겠습니까?');
    if (!confirmDelete) return;

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setErrorMessage('로그인이 필요합니다.');
        return;
      }

      const response = await axios.delete(`/api/memberanking/${encodeURIComponent(id)}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setMembers((prevMembers) => prevMembers.filter((member) => member.id !== id));
        alert('회원이 삭제되었습니다.');
      } else {
        console.error('Unexpected response:', response);
        setErrorMessage('회원 삭제에 실패했습니다.');
      }
    } catch (error) {
      setErrorMessage('회원 삭제에 실패했습니다.');
      console.error('Error deleting member:', error);
    }
  };

  const handleEditClick = (id, points) => {
    setEditingMemberId(id);
    setEditPoints(points || 0);
  };

  const handleSaveClick = async (id) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setErrorMessage('로그인이 필요합니다.');
        return;
      }

      await axios.put(`/api/memberanking/${encodeURIComponent(id)}/points`, { points: editPoints }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setMembers(
        members.map(member =>
          member.id === id ? { ...member, points: editPoints } : member
        )
      );
      setEditingMemberId(null);
      alert('포인트가 수정되었습니다.');
    } catch (error) {
      setErrorMessage('포인트 수정에 실패했습니다.');
      console.error('Error updating points:', error);
    }
  };

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedMembers = [...members].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setMembers(sortedMembers);
  };

  return (
    <div>
      <h2>회원 목록</h2>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <table className="members-table">
        <thead>
          <tr>
            <th onClick={() => requestSort('id')}>아이디</th>
            <th onClick={() => requestSort('username')}>별칭</th>
            <th onClick={() => requestSort('phone')}>전화번호</th>
            <th onClick={() => requestSort('email')}>이메일</th>
            <th onClick={() => requestSort('points')}>포인트</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {members.length > 0 ? (
            members.map((member) => (
              <tr key={member.id}>
                <td>
                  <Link to={`/profile/${member.id}`}>{member.id}</Link> {/* 관리자가 해당 회원의 정보를 볼 수 있도록 링크 */}
                </td>
                <td>{member.username}</td>
                <td>{member.phone}</td>
                <td>{member.email}</td>
                <td>{member.points}</td>
                <td>
                  {editingMemberId === member.id ? (
                    <>
                      <input
                        type="number"
                        value={editPoints}
                        onChange={(e) => setEditPoints(e.target.value)}
                      />
                      <button onClick={() => handleSaveClick(member.id)}>저장</button>
                      <button onClick={() => setEditingMemberId(null)}>취소</button>
                    </>
                  ) : (
                    <>
                      <button onClick={() => handleEditClick(member.id, member.points)}>수정</button>
                      <button onClick={() => deleteMember(member.id)}>삭제</button>
                    </>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">회원 정보가 없습니다.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Amemberl;
