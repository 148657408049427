import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import "../../css/ad.css";

const Ad2 = ({ adName }) => {
  const [adContent, setAdContent] = useState({
    text: "",
    imageUrl: "",
    linkUrl: "",
  });
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/adContents.json");
        setAdContent(response.data[adName] || { text: "", imageUrl: "", linkUrl: "" });
      } catch (error) {
        console.error("Error fetching ad content:", error);
      }
    };

    fetchData();
  }, [adName]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      setPosition((prevPosition) => {
        let newTop = prevPosition.top;
        let newLeft = prevPosition.left;

        switch (e.key) {
          case "ArrowUp":
            newTop = Math.max(newTop - 10, 0);
            break;
          case "ArrowDown":
            newTop = Math.min(newTop + 10, window.innerHeight - 200);
            break;
          case "ArrowLeft":
            newLeft = Math.max(newLeft - 10, 0);
            break;
          case "ArrowRight":
            newLeft = Math.min(newLeft + 10, window.innerWidth - 200);
            break;
          default:
            break;
        }

        return { top: newTop, left: newLeft };
      });
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const { text, imageUrl, linkUrl } = adContent;

  return (
    <div className="ad-container">
      <h2>{adName}</h2>
      <div className="ad-content">
        <p>{text}</p>
        <a href={linkUrl}>
          <img
            src={process.env.PUBLIC_URL + imageUrl} // PUBLIC_URL 사용
            alt="광고 이미지"
            style={{
              position: "absolute",
              top: `${position.top}px`,
              left: `${position.left}px`,
            }}
          />
        </a>
      </div>
    </div>
  );
};

Ad2.propTypes = {
  adName: PropTypes.oneOf(["ad1", "ad2", "ad3"]).isRequired,
};

export default Ad2;
