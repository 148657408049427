import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../css/gy.css'; // CSS 파일을 import
import RankingsTable from '../Rkt'; // RankingsTable 컴포넌트 import

const Gy = () => {
  const [announcementData, setAnnouncementData] = useState([]);
  const [rankingData, setRankingData] = useState([]); // 랭킹 데이터 추가
  const [sortConfig, setSortConfig] = useState({ key: 'number', direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // 페이지당 표시할 아이템 수

  const fixedBilliardsName = "신사동 수정당구장1"; // 필터링할 당구장 이름

  useEffect(() => {
    const fetchAnnouncement = async () => {
      try {
        const response = await axios.get("/gyContents.json"); // JSON 파일을 가져옴
        const data = Object.values(response.data); // JSON 객체를 배열로 변환
        setAnnouncementData(data); // 공지사항 배열을 저장
      } catch (error) {
        console.error("Error fetching announcement:", error);
      }
    };

    const fetchRankingData = async () => {
      try {
        const response = await axios.get("https://chikka.shop/api/rankings"); // API로 랭킹 데이터 가져옴
        const data = Array.isArray(response.data) ? response.data : []; // 배열이 아니면 빈 배열로 처리

        // 당구장 이름이 "광교 수정당구장1"인 데이터만 필터링
        const filteredData = data.filter(rank => rank.billiards === fixedBilliardsName);

        console.log("Fetched ranking data filtered by billiards:", filteredData); // 데이터 확인용 로그
        setRankingData(filteredData); // 필터링된 데이터로 설정
      } catch (error) {
        console.error("Error fetching ranking data:", error);
        setRankingData([]); // 오류 발생 시 빈 배열로 설정
      }
    };

    fetchAnnouncement();
    fetchRankingData();
  }, []);

  const imageStyle = {
    maxWidth: "100%",
    height: "auto",
    marginTop: "20px",
  };

  // 정렬 함수
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // 정렬된 데이터 반환
  const sortedRankingData = React.useMemo(() => {
    let sortedData = [...rankingData];
    if (sortConfig.key) {
      sortedData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedData;
  }, [rankingData, sortConfig]);

  // 페이지에 따른 아이템 분할
  const currentItems = sortedRankingData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // 페이지 네비게이션 핸들러
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h1>GY 당구장</h1>
      
      {/* 공지사항 출력 */}
      {announcementData.length > 0 ? (
        announcementData.map((item, index) => (
          <div key={index} style={{ marginBottom: "40px" }}>
            <p style={{ fontSize: "18px", color: "#333", margin: "20px 0" }}>
              {item.announcement}
            </p>
            {item.imageUrl && (
              <img
                style={imageStyle}
                src={process.env.PUBLIC_URL + item.imageUrl} // 절대 경로로 이미지 로드 (public 폴더 내 이미지)
                alt="GY 공지 이미지"
              />
            )}
          </div>
        ))
      ) : (
        <p>공지사항이 없습니다.</p>
      )}

      {/* RankingsTable 출력 */}
      <h2>랭킹 테이블</h2>
      {rankingData.length > 0 ? (
        <>
          <RankingsTable
            currentItems={currentItems}
            sortConfig={sortConfig}
            requestSort={requestSort}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
          />
          {/* 페이지 네비게이션 */}
          <div className="pagination">
            {Array.from({ length: Math.ceil(sortedRankingData.length / itemsPerPage) }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => handlePageChange(i + 1)}
                className={currentPage === i + 1 ? "active" : ""}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </>
      ) : (
        <p>랭킹 데이터가 없습니다.</p>
      )}
    </div>
  );
};

export default Gy;
