import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pag from '../Pag'; // 페이지네이션 컴포넌트 불러오기
import '../../css/region.css'; // CSS 파일 임포트

const Regionpage = () => {
  const [billiardHalls, setBilliardHalls] = useState([]);
  const [searchField, setSearchField] = useState('all'); // 검색 필드 상태 추가 (기본값: 전체 검색)
  const [searchTerm, setSearchTerm] = useState(''); // 검색어 상태 추가
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' }); // 정렬 상태
  const [currentPage, setCurrentPage] = useState(1); // 페이지 상태 추가
  const itemsPerPage = 10; // 페이지당 항목 수 설정

  useEffect(() => {
    const fetchBilliardHalls = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = token ? { Authorization: `Bearer ${token}` } : {};
  
        const response = await axios.get('/api/billiard-halls', { headers });
  
        if (Array.isArray(response.data)) {
          setBilliardHalls(response.data);
        } else {
          console.error('Unexpected data format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching billiard halls:', error);
      }
    };
  
    fetchBilliardHalls();
  }, []);

  // 번호 추가 함수
  const addRowNumber = (items, currentPage, itemsPerPage, sortConfig, totalItems) => {
    return items.map((item, index) => {
      const baseIndex = (currentPage - 1) * itemsPerPage + index + 1;
      const rowNumber = sortConfig.direction === 'asc'
        ? baseIndex
        : totalItems - baseIndex + 1; // 내림차순일 때 번호 부여
      return { ...item, number: rowNumber };
    });
  };

  // 정렬 함수
  const sortData = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sorted = [...billiardHalls].sort((a, b) => {
      const aValue = a[key] || '';  // undefined일 경우 빈 문자열로 설정
      const bValue = b[key] || '';  // undefined일 경우 빈 문자열로 설정

      if (key === 'id') {
        return direction === 'asc' ? a.id - b.id : b.id - a.id;
      }
      if (key === 'region' || key === 'name' || key === 'address') {
        return direction === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      return direction === 'asc' ? a[key] - b[key] : b[key] - a[key];
    });

    setBilliardHalls(sorted);
  };

  const renderArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? '▲' : '▼';
    }
    return '▲'; // 기본 화살표
  };

  // 검색어로 필터링된 항목 계산 (전체 검색 포함)
  const filteredHalls = billiardHalls.filter((hall) => {
    const searchTermLower = searchTerm.toLowerCase();
    
    // 전체 필드를 검색할 때
    if (searchField === 'all') {
      return (
        hall.region?.toLowerCase().includes(searchTermLower) ||
        hall.name?.toLowerCase().includes(searchTermLower) ||
        hall.address?.toLowerCase().includes(searchTermLower)
      );
    }
    
    // 특정 필드를 검색할 때
    return hall[searchField]?.toString().toLowerCase().includes(searchTermLower);
  });

  // 현재 페이지 항목 계산
  const currentItems = filteredHalls.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const itemsWithRowNumber = addRowNumber(
    currentItems,
    currentPage,
    itemsPerPage,
    sortConfig,
    filteredHalls.length
  );

  const totalPages = Math.ceil(filteredHalls.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchClick = () => {
    setCurrentPage(1); // 검색 시 페이지를 첫 페이지로 설정
  };

  // 빈 셀 추가 함수 (최소 10개 행 유지)
  const renderRows = () => {
    let rows = itemsWithRowNumber.map((hall) => (
      <tr key={hall.id}> {/* 고유한 key로 hall.id 사용 */}
        <td>{hall.number}</td>
        <td>{hall.region || '지역 없음'}</td>
        <td>{hall.address || '주소 없음'}</td>
        <td>{hall.name || '당구장 이름 없음'}</td>       
        <td>
          {hall.website ? (
            <a href={hall.website} target="_blank" rel="noopener noreferrer">
              {hall.website}
            </a>
          ) : (
            '사이트 없음'
          )}
        </td>
        <td>
          {hall.youtube ? (
            <a href={hall.youtube} target="_blank" rel="noopener noreferrer">
              {hall.youtube}
            </a>
          ) : (
            '유튜브 없음'
          )}
        </td>
      </tr>
    ));
  
    // 빈 행 추가 (최소 10행 유지)
    while (rows.length < itemsPerPage) {
      rows.push(
        <tr key={`empty-${rows.length}`}>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
      );
    }
  
    return rows;
  };

  return (
    <div className="Regionpage-container">
      <h2>지역별 당구장 랭킹</h2>

      {/* 검색창 추가 */}
      <div className="search-bar-container">
        <div className="search-bar">
          <select
            className="search-bar__select"
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
          >
            <option value="all">전체</option> {/* 전체 검색 옵션 추가 */}
            <option value="region">지역</option>
            <option value="name">당구장 이름</option>
            <option value="address">주소</option>
          </select>
          <input
            className="search-bar__input"
            type="text"
            placeholder="검색어를 입력하세요"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button onClick={handleSearchClick}>검색</button>
        </div>
      </div>

      <table className="ranking-table">
        <thead>
          <tr>
            <th onClick={() => sortData('id')} style={{ width: '8%' }}>
              번호 {renderArrow('id')}
            </th>
            <th onClick={() => sortData('region')} style={{ width: '15%' }}>
              지역 {renderArrow('region')}
            </th>
            <th onClick={() => sortData('address')} style={{ width: '30%' }}>
              주소 {renderArrow('address')}
            </th>
            <th onClick={() => sortData('name')} style={{ width: '20%' }}>
              당구장 이름 {renderArrow('name')}
            </th>            
            <th style={{ width: '17%' }}>사이트 주소</th>
            <th style={{ width: '10%' }}>유튜브 주소</th>
          </tr>
        </thead>
        <tbody>
          {renderRows()}
        </tbody>
      </table>

      {/* 페이지 네비게이션 추가 */}
      <Pag
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default Regionpage;
