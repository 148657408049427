import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Uprofp = ({ user, setUser }) => {
  const [isEditingAdditionalInfo, setIsEditingAdditionalInfo] = useState(false); // 추가 정보 수정 모드
  const [updatedScore4, setUpdatedScore4] = useState(user.score4 || '');
  const [updatedScore3, setUpdatedScore3] = useState(user.score3 || '');
  const [updatedRegion, setUpdatedRegion] = useState(user.region || ''); // 지역 수정 상태 추가
  const [updatedBilliardHall, setUpdatedBilliardHall] = useState(user.billiardHall || ''); // 필드명 변경
  const [billiardHalls, setBilliardHalls] = useState([]); // 당구장 목록 상태 추가
  const [regions, setRegions] = useState([]); // 지역 목록 상태 추가
  const [score4Options] = useState([30, 50, 80, 100, 120, 150, 200, 250, 300, 400, 500, 700, 1000, 1500, 2000]);

  // 당구장 목록을 API로부터 가져오는 함수
  useEffect(() => {
    const fetchBilliardHalls = async () => {
      try {
        const response = await axios.get('/api/billiard-halls'); // 백엔드 API 호출
        setBilliardHalls(response.data); // 응답 데이터를 상태에 저장

        // 지역 목록 추출 (중복 제거)
        const uniqueRegions = [...new Set(response.data.map(hall => hall.region))];
        setRegions(uniqueRegions); // 지역 목록 상태에 저장
      } catch (error) {
        console.error('당구장 목록을 불러오는 데 실패했습니다.', error);
        alert('당구장 목록을 불러오는 데 실패했습니다.');
      }
    };

    fetchBilliardHalls(); // 컴포넌트 마운트 시 API 호출
  }, []);

  // 추가 정보 수정 시 포인트 차감 함수
  const handleUpdateAdditionalInfo = async () => {
    const confirmed = window.confirm('추가 정보 수정 시 100포인트가 차감됩니다. 계속하시겠습니까?');
    if (!confirmed) return;

    try {
      const token = localStorage.getItem('token');
      const userId = user.id;

      if (!token || !userId) {
        alert('로그인이 필요합니다.');
        return;
      }

      // 100포인트 차감
      if (user.points < 100) {
        alert('포인트가 부족합니다.');
        return;
      }

      const newPoints = user.points - 100;

      // 추가 정보 업데이트 API 호출
      const response = await axios.put(
        `/api/memberanking/${userId}/update`,
        {
          score4: updatedScore4,
          score3: updatedScore3,
          region: updatedRegion,
          billiardHall: updatedBilliardHall, // 여기서도 필드명 변경
          points: newPoints, // 포인트 차감
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // 기존의 기본 정보는 유지하고, 추가 정보만 업데이트
      setUser((prevUser) => ({
        ...prevUser,
        score4: response.data.score4,
        score3: response.data.score3,
        region: response.data.region,
        billiardHall: response.data.billiardHall,
        points: response.data.points,
      }));

      setIsEditingAdditionalInfo(false);
      alert('추가 정보가 수정되었습니다. 100포인트가 차감되었습니다.');
    } catch (error) {
      alert('추가 정보 수정에 실패했습니다.');
    }
  };

  return (
    <div className="additional-info">
      <h3>추가 정보</h3>
      {isEditingAdditionalInfo ? (
        <div>
          <label>
            4구 점수:
            <select value={updatedScore4} onChange={(e) => setUpdatedScore4(e.target.value)}>
              {score4Options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </label>
          <label>
            3구 점수:
            <input
              type="number"
              value={updatedScore3}
              onChange={(e) => setUpdatedScore3(e.target.value)}
            />
          </label>
          {/* 지역 드롭다운 추가 */}
          <label>
            지역:
            <select value={updatedRegion} onChange={(e) => setUpdatedRegion(e.target.value)}>
              <option value="">지역을 선택하세요</option>
              {regions.length > 0 ? (
                regions.map((region, index) => (
                  <option key={index} value={region}>
                    {region}
                  </option>
                ))
              ) : (
                <option value="">지역이 없습니다</option>
              )}
            </select>
          </label>
          <label>
            당구장:
            <select value={updatedBilliardHall} onChange={(e) => setUpdatedBilliardHall(e.target.value)}>
              <option value="">당구장을 선택하세요</option>
              {billiardHalls.length > 0 ? (
                billiardHalls.map((hall) => (
                  <option key={hall.id} value={hall.name}>
                    {hall.name}
                  </option>
                ))
              ) : (
                <option value="">당구장이 없습니다</option>
              )}
            </select>
          </label>
          <button onClick={handleUpdateAdditionalInfo}>저장</button>
          <button className="cancel-btn" onClick={() => setIsEditingAdditionalInfo(false)}>취소</button>
        </div>
      ) : (
        <div>
          <p>4구 점수: {user.score4 || '정보 없음'}</p>
          <p>3구 점수: {user.score3 || '정보 없음'}</p>
          <p>지역: {user.region || '정보 없음'}</p>
          <p>당구장: {user.billiardHall || '정보 없음'}</p> {/* 필드명 변경 */}
          <button onClick={() => setIsEditingAdditionalInfo(true)}>추가 정보 수정</button>
        </div>
      )}
    </div>
  );
};

export default Uprofp;
