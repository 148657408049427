import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/tnp.css";

const Tournamentsetup = () => {
  const [numPlayers, setNumPlayers] = useState(4); // 기본 참가 인원 설정
  const navigate = useNavigate();

  const handleStartTournament = () => {
    // 토너먼트 페이지로 참가 인원 수를 전달하며 이동
    navigate("/tournament", { state: { numPlayers } });
  };

  return (
    <div className="tournament-setup-container">
      <h1>당구장 토너먼트 설정</h1>
      <div className="setup-content">
        <label>참가 인원을 선택하세요:</label>
        <select
          value={numPlayers}
          onChange={(e) => setNumPlayers(parseInt(e.target.value))}
        >
          {[4, 8, 16, 32, 64].map((n) => (
            <option key={n} value={n}>
              {n}명
            </option>
          ))}
        </select>
        <button onClick={handleStartTournament}>토너먼트 인원설정완료</button>
      </div>
    </div>
  );
};

export default Tournamentsetup;
